/* Form group */

.form-group {
  margin-bottom: $margin-bottom-30;
  position: relative;
  label {
    font-size: 14px;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 0;
    font-weight: 400;
    color: $grey;
    text-align: left;
    display: block;
  }
  .error {
    color: $danger;
    font-size: 12px;
    // position: absolute;
    // left: 0;
    // bottom: -20px;
  }
  // &.input-with-icon{
  //   display: flex;
  //   align-items: center;
  //   .input-left-icon{
  //     position: absolute;
  //     left: 18px;
  //   }
  //   .form-control{
  //     border:2px solid $search-input-border;
  //     border-radius: 50px;
  //     background: $primary;
  //     padding: 5px 20px 5px 40px;
  //     font-size: $font-14;
  //     &:focus {
  //       color: $text-primary;
  //       background-color: $primary;
  //       border-color: $search-input-border;
  //       outline: 0;
  //       -webkit-box-shadow: none;
  //       box-shadow: none;
  //     }
  //   }
  // }
  &.input-with-icon {
    display: flex;
    align-items: center;
    .input-left-icon {
      position: absolute;
      left: 18px;
    }
    .span-text {
      position: absolute;
      left: 18px;
    }
    .form-control {
      border: 1px solid $border-light;
      border-radius: 3px;
      background: $white;
      padding: 5px 20px 5px 50px;
      font-size: $font-14;
      // &:focus {
      //   color: $text-primary;
      //   background-color: $primary;
      //   border-color: $search-input-border;
      //   outline: 0;
      //   -webkit-box-shadow: none;
      //   box-shadow: none;
      // }
    }
  }
}

.main-loc-dropdown {
  &:disabled {
    background-color: white !important;
    opacity: 0.5;
  }
}
/* Form Control */
.form-control {
  background: $white;
  border: 1px solid $input-border;
  border-radius: 4px;
  height: 40px;
  color: $text-primary;
  font-size: 16px;
  &:focus {
    color: $text-primary;
    background-color: $white;
    border-color: $input-border;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &::-webkit-input-placeholder {
    color: rgba(37, 35, 80, 0.3);
  }
  &::-moz-placeholder {
    color: rgba(37, 35, 80, 0.3);
  }
  &:-ms-input-placeholder {
    color: rgba(37, 35, 80, 0.3);
  }
  &:-moz-placeholder {
    color: rgba(37, 35, 80, 0.3);
  }

  &.white-placeholder {
    &::-webkit-input-placeholder {
      color: $white;
    }
    &::-moz-placeholder {
      color: $white;
    }
    &:-ms-input-placeholder {
      color: $white;
    }
    &:-moz-placeholder {
      color: $white;
    }
  }
}

/* Select box */
.select-wrap, .details-wrap {
  .select-box__control {
    min-height: 40px;
  }
  .select-box__indicator-separator {
    display: none !important;
  }
  .select-box__singleValue {
    color: $primary;
    font-size: $font-16;
    line-height: 24px;
  }

  .select-box__indicator {
    color: $select-arrow !important;
  }
  .select-box__placeholder {
    color: #858585 !important;
  }
  .select-box__control,
  .select-box__control {
    box-shadow: none;
  
    &:hover,
    &:focus {
      border-color: $input-border;
      box-shadow: none;
    }
  }
  .select-box__option--is-selected {
    background-color: $selectbox-selected-item-bg !important;
    color: $primary;
  }
  .select-box__option--is-focused {
    background-color: $list-hover-bg;
  }
}
.selectbox-with-icon {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    &::before {
      color: $icon-color;
    }
  }
  .select-wrap {
    .select-box__control {
      border: none;
      width: auto;
    }
    .select-box__indicator {
      color: $select-down-arrow !important;
      padding: 0;
    }
    .select-box__placeholder {
      position: static;
      color: $default-color !important;
      transform: none;
      font-weight: normal;
      font-size: $font-14;
      line-height: 24px;
    }
    .select-box__single-value {
      position: static;
      color: $default-color;
      font-weight: normal;
      font-size: $font-14;
      line-height: 24px;
      transform: none;
    }
    .select-box__control {
      min-height: inherit;
    }
  }
}
.select-wrap {
  &.selectbox-no-border {
    .select-box__control {
      border: none;
      width: auto;
    }
    .select-box__indicator {
      color: $select-down-arrow !important;
      padding: 0;
    }
    .select-box__value-container {
      padding: 0;
    }
    .select-box__placeholder {
      position: static;
      color: $primary !important;
      transform: none;
      font-weight: 500;
      font-size: $font-24;
      line-height: 24px;
    }
    .select-box__single-value {
      position: static;
      color: $primary;
      font-weight: 500;
      font-size: $font-24;
      line-height: 24px;
      transform: none;
    }
    .select-box__control {
      min-height: inherit;
      display: inline-flex;
    }
  }
}
/* Custom Checkbox */
.custom-checkbox {
  .form-check {
    padding-left: 0;
    cursor: pointer;
  }
  input {
    display: none;
    &:checked {
      & + .form-check-label {
        &::before {
          background-color: #6868fe;
          border: 1px solid #6868fe;
          position: absolute;
          left: 0;
          top: 4px;
        }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 6px;
          top: 6px;
          width: 5px;
          height: 10px;
          border: 1px solid $white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
  .form-check-label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-transform: capitalize;
    &::before {
      content: '';
      display: inline-block;
      border: 1px solid #dbe2f0;
      border-radius: 4px;
      background-color: $white;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 4px;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }
  &.custom-checkbox-with-dot {
    position: relative;
    margin-bottom: 5px;
    .dot-span {
      position: absolute;
      left: 28px;
      width: 5px;
      height: 5px;
      top: 9px;
    }
    .form-check-label {
      padding-left: 58px !important;
      color: $primary-bg;
      padding-top: 2px;
    }
  }
  &.checkbox-with-icon {
    margin-right: 30px;
    .icon {
      position: absolute;
      left: 30px;
    }
    .form-check-label {
      padding-left: 55px;
    }
  }
  &.rounded-chckbox {
    .form-check-label {
      position: relative;
      cursor: pointer;
      padding-left: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-transform: capitalize;
      &::before {
        content: '';
        display: inline-block;
        border: 1px solid rgba(126, 133, 142, 0.5);
        border-radius: 2px;
        background-color: $white;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 4px;
        border-radius: 50px;
      }
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 8px;
        top: 8px;
      }
    }
    input {
      display: none;
      &:checked {
        & + .form-check-label {
          &::before {
            background-color: rgba(126, 133, 142, 0.5);
            position: absolute;
            left: 0;
            top: 4px;
          }
          &::after {
            content: '';
            display: inline-block;
            position: absolute;
            left: 8px;
            top: 8px;
            width: 5px;
            height: 10px;
            border: 1px solid $white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
.text-checkbox-wrap {
  display: flex;
  flex-wrap: wrap;
  .form-check {
    width: auto;
    flex-wrap: wrap;
    display: flex;
    input {
      &:checked {
        & + .form-check-label {
          color: $white;
          background-color: $secondary;
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
    .form-check-label {
      padding-left: 0;
      padding: 0 8px;
      border-radius: 25px;
      display: inline-block;
      margin-right: 10px;
      &::before,
      &::after {
        display: none;
      }
      &.caret {
        display: none;
      }
    }
  }
}
/* Custom Radio button */
.custom-radiobox {
  &.form-check {
    padding-left: 0;
    cursor: pointer;
    margin-right: 30px;
  }
  input {
    display: none;
    &:checked {
      & + .form-check-label {
        &::before {
          background-color: $white;
          position: absolute;
          border-color: $secondary;
          left: 0;
          top: 2px;
        }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          background-color: $secondary;
          left: 4px;
          top: 6px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }
      }
    }
  }
  .form-check-label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: $table-head-text;
    &::before {
      content: '';
      display: inline-block;
      border: 1px solid $text-secondary;
      border-radius: 2px;
      background-color: $white;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 2px;
      border-radius: 100%;
    }
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }
}

/* Buttons */
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  cursor: pointer;
  &.btn-text {
    color: $text-primary;
    box-shadow: none;
    &:hover,
    &:focus {
      color: $text-secondary;
    }
  }
  span {
    margin-right: 10px;
    font-size: 12px;
  }

  &.btn-outlined {
    color: $btn-primary;
    background-color: $white;
    box-shadow: none;
    border: 1px solid $btn-primary;
    span {
      &::before {
        color: $white;
      }
    }
    &:hover,
    &:focus {
      color: $btn-primary;
      background-color: #FBEDE5;
      border: 1px solid $btn-primary;
      span {
        &::before {
          color: $btn-primary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $white;
        background-color: $white;
        box-shadow: none;
        border: 1px solid $btn-primary;
        span {
          &::before {
            color: $white;
          }
        }
      }
    }
    &:disabled,
    &.disabled {
      background-color: $white;
    }
  }

  &.btn-primary {
    color: $white;
    background-color: $btn-primary;
    border: 1px solid $btn-primary;
    text-transform: capitalize;
    span {
      &::before {
        color: $white;
      }
    }
    &:hover,
    &:focus {
      color: $btn-primary;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $btn-primary;
      span {
        &::before {
          color: $btn-primary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $white;
        background-color: $btn-primary;
        box-shadow: none;
        border: 1px solid $btn-primary;
        span {
          &::before {
            color: $white;
          }
        }
      }
    }
    &:disabled,
    &.disabled {
      background-color: $btn-primary;
    }
  }
  &.btn-secondary {
    color: $white;
    background-color: $btn-secondary;
    border: 1px solid $btn-secondary;
    span {
      &::before {
        color: $white;
      }
    }
    &:hover,
    &:focus {
      color: $btn-secondary;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $btn-secondary;
      span {
        &::before {
          color: $btn-secondary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $white;
        background-color: $btn-secondary;
        box-shadow: none;
        border: 1px solid $btn-secondary;
        span {
          &::before {
            color: $white;
          }
        }
      }
    }
    &:disabled,
    &.disabled {
      background-color: $btn-secondary;
    }
  }
  &.btn-primary-dark {
    color: $white;
    background-color: $btn-primary-dark;
    border: 1px solid $btn-primary-dark;
    span {
      &::before {
        color: $white;
      }
    }
    &:hover,
    &:focus {
      color: $btn-primary-dark;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $btn-primary-dark;
      span {
        &::before {
          color: $btn-primary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $btn-primary-dark;
        background-color: $white;
        box-shadow: none;
        border: 1px solid $btn-primary-dark;
        span {
          &::before {
            color: $btn-primary;
          }
        }
      }
    }
  }
  &.btn-dark {
    color: $white;
    background-color: $btn-dark;
    border: 1px solid $btn-dark;
    span {
      &::before {
        color: $white;
      }
    }
    &:hover,
    &:focus {
      color: $btn-dark;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $btn-dark;
      span {
        &::before {
          color: $btn-dark;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $btn-dark;
        background-color: $white;
        box-shadow: none;
        border: 1px solid $btn-dark;
        span {
          &::before {
            color: $btn-dark;
          }
        }
      }
    }
  }
  &:disabled {
    color: #fff;
    background-color: $btn-grey;
    border-color: $btn-grey;
    cursor: not-allowed;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $btn-grey;
      border-color: $btn-grey;
      box-shadow: none;
    }
  }
  &.btn-light {
    color: $primary;
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    span {
      &::before {
        color: $primary;
      }
    }
    &:hover,
    &:focus {
      color: $primary;
      background-color: transparent;
      box-shadow: none;
      span {
        &::before {
          color: $primary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
  &.btn-light-bordered {
    color: $text-secondary;
    background-color: transparent;
    border: 1px solid $text-secondary;
    box-shadow: none;
    span {
      &::before {
        color: $text-secondary;
      }
    }
    &:hover,
    &:focus {
      color: $white;
      background-color: $text-secondary;
      box-shadow: none;
      border: 1px solid $text-secondary;
      span {
        &::before {
          color: $primary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $white;
        background-color: $text-secondary;
        box-shadow: none;
        border: 1px solid $text-secondary;
      }
    }
  }
  &.btn-dashed-bordered {
    border: 1px dashed $text-secondary;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    span {
      &::before {
        color: $primary;
      }
    }
    &:hover,
    &:focus {
      color: $white;
      background-color: $text-secondary;
      box-shadow: none;
      border: 1px solid $text-secondary;
      span {
        &::before {
          color: $white;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $white;
        background-color: $text-secondary;
        box-shadow: none;
        border: 1px solid $text-secondary;
      }
    }
  }
  &.btn-primary-link {
    color: $secondary;
    background-color: transparent;
    border: none;
    box-shadow: none;
    span {
      &::before {
        color: $secondary;
      }
    }
    &:hover,
    &:focus {
      color: $btn-primary;
      background-color: $white;
      background-color: transparent;
      border: none;
      span {
        &::before {
          color: $secondary;
        }
      }
    }
    &:not(:disabled):not(.disabled) {
      &.active,
      &:active {
        color: $btn-primary;
        background-color: $white;
        background-color: transparent;
        border: none;
        span {
          &::before {
            color: $secondary;
          }
        }
      }
    }
    &:disabled {
      color: $secondary;
      background-color: transparent;
      border-color: transparent;
      cursor: not-allowed;
      opacity: 0.5;
      &:hover,
      &:focus {
        color: $secondary;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
  &.attachment-btn {
    font-weight: normal;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    color: $text-secondary-dark;
    box-shadow: none;
    span {
      &::before {
        color: $icon-color;
      }
    }
    &:hover,
    &:focus {
      color: $secondary;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  &.btn-inprogress {
    background: #F58500 !important;
    color: $white;
    &:hover,
    &:focus {
      background: #F58500 !important;
      color: $white;
    }
  }
  &.btn-not-started {
    background: #3B8EF0 !important;
    color: $white;
    &:hover,
    &:focus {
      background: #3B8EF0 !important;
      color: $white;
    }
  }
  &.btn-incomplate {
    background: $red-gradiant !important;
    color: $white;
    &:hover,
    &:focus {
      background: $red-gradiant !important;
      color: $white;
    }
  }
  &.btn-unassigned {
    background: $blue-gradiant !important;
    color: $white;
    &:hover,
    &:focus {
      background: $blue-gradiant !important;
      color: $white;
    }
  }
  &.btn-paused, &.btn-on-hold {
    background: #64636D !important;
    color: $white;
    &:hover,
    &:focus {
      background: #64636D !important;
      color: $white;
    }
  }
}
/* Dropdown */

.common-dropdown {
  position: relative;

    &.bottom-end-dropdown {
      left: 0 !important;
      top: 16px !important;
      transform: translateX(-93%) !important;

  }
  .dropdown-toggle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-shadow: none;
    color: $grey;
    &.blankBg {
      color: gray !important;
      background-color: white !important;
      border: none !important;
      span {
        &::before {
          color: $grey !important;
        }
      }
      &::before {
        color: $grey !important;
      }
      &:hover {
        color: $grey !important;
      }
    }

    &::after {
      display: none;
    }
    // &::before{
    //   content: '';
    //   display: inline-block;
    //   position: absolute;
    //   width: 25px;
    //   height: 25px;
    //   border-radius: 4px;
    //   background-color: $white;
    //   transform: rotate(45deg);
    //   right: 3px;
    //   top: 36px;
    //   opacity: 0;
    //   pointer-events: none;
    //   transition: opacity 0.2s ease;
    // }
  }
  .dropdown-menu {
    border-radius: 6px;
    background: $white;
    padding: 10px;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.18);
    border-radius: 6px;
    border: none;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    top: calc(100% + 6px) !important;
    bottom: auto !important;

    &.right-align-dropdown {
      right: -5px !important;
      left: auto !important;
    }
    &.left-align-dropdown {
      min-width: 220px;
      .avtar-wraper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        h6 {
          margin-bottom: 0;
        }
      }
    }
    .dropdown-header {
      padding: 15px 20px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    h6 {
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
      line-height: 130%;
      .arrow {
        margin: 0 16px;
      }
    }
    .dropdown-item {
      font-weight: normal;
      text-transform: capitalize;
      font-size: $font-14;
      line-height: 24px;
      padding: 12px 15px;
      &.active,
      &:active,
      &:hover,
      &:focus {
        color: $primary;
        text-decoration: none;
        background-color: $light-grey-bg;
      }
      &.archive-ropdown-item {
        color: $text-archive;
      }
      .dropdown-icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
    }
    &.no-animation-dropdown {
      animation: none;
      right: 0 !important;
      top: 0 !important;
    }
  }
  &.notification-dropdown {
    &.show {
      .dropdown-toggle {
        &::before {
          opacity: 1;
        }
      }
    }
    .dropdown-toggle {
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background-color: $white;
        transform: rotate(45deg);
        right: 3px;
        top: 36px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
      }
    }
    .dropdown-menu {
      &.right-align-dropdown {
        right: -25px;
        left: auto;
      }
    }
  }
}

/* Upload images */
.avatar-upload {
  .imgbox {
    margin-bottom: $margin-bottom-20;
    background: $grey url('../../img/user.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px;
  }
  .imgupload-btn-wrap {
    position: relative;
    .form-file-label {
      cursor: pointer;
      position: relative;
      z-index: 1;
    }
    .form-control-file {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      cursor: pointer;
      z-index: 0;
      opacity: 0;
    }
  }
}

/* Time Picker */
.time-input-box {
  .rc-time-picker {
    .rc-time-picker-input {
      width: 80px;
    }
  }
}
/* Gradiant Backgrount */
.green-gradiant-bg {
  background: $green-gradiant !important;
}
.orange-gradiant-bg {
  background: $orange-gradiant !important;
}
.red-gradiant-bg {
  background: $red-gradiant !important;
}
.blue-gradiant-bg {
  background: $blue-gradiant !important;
}

/* ProgressBar */
.progress {
  width: 100%;
  &.task-progressbar-wrap {
    height: 4px;
    position: absolute;
    left: 0;
    top: -2px;
    background-color: $border-light;
    .progress-bar {
      border-radius: 5px;
      background: $orange-gradiant;
    }
    &.inprogress-bar {
      .progress-bar {
        background: $red-gradiant;
      }
    }
  }
  &.checklist-progressbar-wrap {
    height: 2px;
    overflow: visible;
    background-color: $border-light;
    .progress-bar {
      border-radius: 5px;
      background: $green-gradiant;
      height: 5px !important;
      position: relative;
      top: -1px;
    }
  }
}

/* Custom Switch */
.switch-wrap {
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $border-light;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 50px;
      &::before {
        border-radius: 50%;
      }
    }
  }

  input:checked + .slider {
    background-color: $secondary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $secondary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}

.custom-selectbox {
  // width: auto;
}

.custom-checkbox.custom-checkbox-with-dot .ring-span {
  position: absolute;
  border: 4px solid;
  box-sizing: border-box;
  border-radius: 34px;
  display: inline-block;
  left: 32px;
  width: 16px;
  height: 16px;
  top: 4px;
}

.custom-checkbox.custom-checkbox-with-dot .priority-span {
  position: absolute;
  display: inline-block;
  left: 32px;
  width: 16px;
  height: 16px;
  top: 4px;
}

.custom-checkbox {
  &.form-group {
    margin-bottom: 18px;
  }
}

.task-description-form {
  .form-control {
    border-color: #d8dadd;
  }
  .rc-time-picker-input {
    border: 0;
    font-size: 14px;
  }
  .select-box__control {
    border: 1px solid #d8dadd !important;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .select-box__indicator {
    margin-right: 5px;
  }
  .DayPickerInput {
    margin: 0 !important;
  }
  .css-1rhbuit-multiValue {
    background-color: #f2f5f9 !important;
  }
  .select-box__multi-value__remove {
    width: 22px;
    height: 22px;
    border-radius: 64px !important;
    font-weight: 100;
    background-color: #ececf2;
  }
  .select-box__multi-value__remove {
    &:hover {
      background-color: #ececf2 !important;
      color: rgba(75, 74, 94, 0.7) !important;
      cursor: pointer;
    }
  }
  .select-box__multi-value {
    color: rgba(75, 74, 94, 0.7) !important;
    border-radius: 6px !important;
    padding: 4px 8px;
  }
  // TODO: make it generic to be applied to all the input fields
  .additional-desc {
    & > input {
      border: 0;
      width: inherit;
      // color: #9291A7;
      &::-webkit-input-placeholder {
        /* Edge */
        color: #9291a7;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9291a7;
      }
      &::placeholder {
        color: #9291a7;
      }
    }
  }
  .week-days {
    .form-check-label {
      border-radius: 6px;
      padding: 6px 12px;
      margin-right: 8px;
    }
  }
}

.location-container-bg {
  background: #f2f5f9;
  border-radius: 6px;
  padding: 16px;
}

.attachment-row {
  border-bottom: 1px solid rgba(219, 226, 240, 0.5);
  color: #252350;
  font-size: 14px;
}
.removeChecklistButton {
  width: 23px;
}
.attachment-extra {
  color: #7e858e;
  font-size: 12px;
  display: inline-block;
}

.add-attachment-button {
  border: 1px dashed #7571c5;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.add-checklist-button {
  border-radius: 4px;
  background: #ececf2;
  font-size: 14px;
  width: 200px;
}

.label-suffix {
  color: #bebdcb;
}

.task-assignment-wrapper {
  line-height: 38px;
  box-sizing: border-box;
  border-radius: 4px;
}

.employee-item {
  padding: 0px 10px;
  &:hover {
    background: #f2f5f9;
    border-radius: 4px;
  }
  label.form-label {
    margin-bottom: 0 !important;
    width: 100%;
    cursor: pointer;
  }
}

.grey-bg-wrapper {
  background: #f2f5f9;
  border-radius: 6px;
  padding: 4px;
  font-size: 14px;
}

.priority-item {
  padding: 6px 8px;
  &:hover {
    background: #ffffff;
    border-radius: 4px;
  }
}

.priority-item-selected {
  padding: 6px 8px;
  background: $v2-orange;
  border-radius: 4px;
  color: #ffffff;
}

.recurring-radio {
  background: #dbe2f0;
  border-radius: 6px;
  .checked-bg {
    border-radius: 6px;
    background: #ffffff;
  }
}

.react-tel-input {
  input {
    font-family: 'Manrope';
  }
}

.comments-list {
  .str-chat__li--bottom,
  .str-chat__li--single {
    .common-dropdown {
      .dropdown-menu {
        top: calc(100% + -30px) !important;
      }
    }
  }
}

.comments-list {
  .str-chat__li--bottom {
    .common-dropdown {
      .dropdown-menu {
        top: calc(100% + -140px) !important;
      }
    }
  }
}

.create-group-form,
#message-list {
  .str-chat__li--bottom {
    .common-dropdown {
      .dropdown-menu {
        top: calc(100% + -105px) !important;
      }
    }
  }
}

.create-group-form,
#message-list {
  .str-chat__li--single {
    .common-dropdown {
      .dropdown-menu {
        top: calc(100% + -80px) !important;
      }
    }
  }
}

.status-btn {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  height: 32px;
  min-width: 120px;
  border-radius: 46px;
}
