.profile-layout {
  background-color: $body-light-bg;
  height: calc(100vh - 62px) !important;
  position: relative;
  padding-top: 35px;
  .profile-title {
    margin-bottom: $margin-bottom-25;
  }

  .back-btn {
    position: absolute;
    top: 40px;
    left: 60px;
    box-shadow: none;
    @include media(down, $breakpoint-sm) {
      left: 15px;
    }
    span {
      &::before {
        color: $dark-black;
        font-size: 18px;
      }
    }
  }
  .profile-wrap {
    display: flex;
    .img-wrap {
      margin-right: 24px;
      flex-shrink: 0;
      .imgbox {
        width: 166px;
        height: 166px;
        border-radius: 3px;
        margin-bottom: 10px;
        overflow: hidden;
      }
      .imgupload-btn-wrap {
        cursor: pointer;
        .form-file {
          .form-control-file {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            width: 100%;
            top: 0;
          }
          .form-file-label {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            width: 100%;
            z-index: 1;
          }
        }

        .edit-btn {
          font-weight: 600;
          font-size: $font-12;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          cursor: pointer;
          img {
            margin-right: 10px;
          }
        }
      }
    }
    .profile-info {
      width: calc(100% - 190px);
      background-color: $white;
      border-radius: 4px;
      .info-wrap {
        padding: 27px 25px;
        .info {
          margin-bottom: $margin-bottom-15;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $text-secondary;
          }
          h6 {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: $text-primary;
          }
        }
      }

      .btnwrap {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 15px 25px;

        .change-password-btn {
          background-color: transparent;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .alert-msg {
    position: absolute;
    right: 36px;
    bottom: 36px;
  }
}
