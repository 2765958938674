.group-locations .ag-header {
  margin-bottom: unset;
}

.group-locations .ag-header-cell {
  background: #f5f5f5;
  padding: 0px 16px;

}
.group-locations .ag-header-cell-resize {
  opacity: 0;
}
.group-locations .ag-header-cell-resize:hover {
  opacity: 1;
}

.group-locations .actions-container {
  display: none;
}

.group-locations .ag-cell {
  padding: 0px 16px;
}

.group-locations .ag-header-container {
  background: #f5f5f5;
}

.group-locations .ag-root-wrapper {
  background: none;
}

.group-locations .ag-row {
  border-radius: 8px;
  overflow: hidden;
  height: 52px;
  background-color: #fff !important;
}

.group-locations .ag-row-level-0 {
  border-bottom: 6px solid #f5f5f5 !important;
}

.group-locations .ag-row-group-expanded {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0px !important;
}

.group-locations .ag-row:hover {
  border: 1px solid rgba(104, 104, 254, 1) !important;
  background: rgba(235, 234, 255, 1);
  z-index: 10000;
  height: 50px !important;
}

.group-locations .ag-row-selected.ag-row:hover {
  border: none !important;
}

.group-locations .ag-body-vertical-scroll-viewport {
  background-color: #f5f5f5;
}

.group-locations .ag-row-selected::before {
  border: 1px solid rgba(104, 104, 254, 1) !important;
  background: rgba(235, 234, 255, 1);
  border-radius: 8px;
}
