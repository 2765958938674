/*Headings*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  color: $primary;
  margin-bottom: 0;
}
h1,
.h1 {
}
h2,
.h2 {
  font-size: $font-24;
  line-height: 32px;
}
h3,
.h3 {
  font-size: $font-20;
  line-height: 24px;
}
h4,
.h4 {
  font-size: $font-18;
  line-height: 24px;
}
h5,
.h5 {
  font-size: $font-16;
  line-height: 24px;
}
h6,
.h6 {
  font-size: $font-14;
  line-height: 24px;
}
