/* @tailwind base;
@tailwind components;
@tailwind utilities; */

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}
input::-ms-reveal {
  display: none !important;
}

/*TODO: remove it after setting up tailwindcss*/
.right-1\/2 {
  right: 50%;
}
