.layout-wrap {
  display: flex;
  height: auto;
}
.common-content-wrap {
  width: 100%;
}

.content-title-wrap {
  padding: 12px 40px;
  background-color: $white;
  min-height: 64px;
  .title {
    display: flex;
    align-items: center;
    font-weight: normal;
    line-height: 24px;
    span {
      color: $grey;
      font-size: $font-14;
      margin-left: 5px;
      line-height: normal;
    }
  }
  .add-btn {
    padding: 8px 15px;
  }
}
.action-btn-wrap {
  .dropdown-toggle {
    padding: 0 5px;
    span {
      margin-right: 0 !important;
    }
  }
}

.admin-content-wrap {
  padding: 20px 40px;
}
.satus-count-wrap {
  display: flex;
  justify-content: flex-end;
  span {
    min-width: 35px;
    &.satus-count-box {
      margin-left: 50px;
      span {
        margin-left: 10px;
      }
    }
  }
}

/* Badge css */
.badge {
  border: 2px solid $white;
  border-radius: 49px;
  height: 26px;
  font-weight: normal;
  font-size: $font-15;
  line-height: 24px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.green-badge {
    background-color: $green-light-bg;
    color: $badge-green-text;
  }
  &.orange-badge {
    background-color: $orange-light-bg;
    color: $badge-orange-text;
  }
  &.brown-badge {
    background-color: $brown-light-bg;
    color: $badge-brown-text;
  }
}

/* Table */
.avtar-imgbox {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}
.normal-table {
  border-spacing: 0 8px;
  border-collapse: separate;

  &.table-hover {
    tbody {
      tr {
        &:hover {
          box-shadow: 1px 1px 10px $table-box-shadow;
          background-color: $white;
        }

        &.nodata {
          box-shadow: none !important;
        }
        td {
          .action-btn-wrap {
            .action-dropdown {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  thead {
    th {
      border-top: none;
      border-bottom: none;
      font-weight: 500;
      font-size: $font-12;
      line-height: 24px;
      color: $primary;
      text-transform: uppercase;
      padding: 12px 0px 12px 16px;
      white-space: nowrap;
    }
  }
  tbody {
    tr {
      background-color: $white;
      border-radius: 3px;
      box-shadow: 1px 1px 10px transparent;
      &.active {
        td {
          color: $text-primary;
          h6 {
            color: $text-primary;
          }
        }
      }
      td {
        border-top: none;
        vertical-align: middle;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $text-secondary;
        padding: 12px 0px 12px 16px;
        word-break: break-all;
        white-space: nowrap;
        &:last-child {
          padding-right: 15px;
        }
        .avtar-img-outer {
          position: relative;
          width: 28px;
          .icon-setting {
            position: absolute;
            bottom: -4px;
            right: -8px;
            border: 2px solid $white;
            border-radius: 50px;
            background-color: $white;
            font-size: 12px;
          }
        }
        h6 {
          font-size: 14px;
          line-height: 17px;
          color: $text-secondary;
          font-weight: 500;
        }
        h5 {
          font-size: 14px;
          line-height: 17px;
          color: $text-primary;
          font-weight: 500;
        }
        span {
          &.sub-title {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
          }
        }
        .status {
          position: relative;
          display: flex;
          align-items: center;
          .dot-span {
            position: absolute;
            left: -18px;
          }
        }
        .action-btn-wrap {
          width: 15px;
          float: right;
          display: flex;
          justify-content: center;
          padding: 5px;
          .action-dropdown {
            display: none;
            span {
              color: $primary;
            }
          }
        }

        .name-wrap {
          .imgbox {
            border-radius: 50px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.member-wrap {
  align-items: center;
  display: flex;
  position: relative;
  .member-group {
    margin-right: 15px;
  }
  .info-wrap {
    margin-left: 0px;
    h6 {
      font-weight: normal;
      font-size: $font-14;
      line-height: 24px;
      color: $table-head-text;
    }
  }
}
.member-group {
  position: relative;
  align-items: center;
  display: flex;
  .avtar-imgbox {
    background-color: $grey;
    border: 2px solid $white;
    width: 30px;
    height: 30px;
    margin-right: 2px;
    &.other-avtar-imgbox {
      position: absolute;
      left: 10px;
    }
  }
}
.extra-memers-count {
  background-color: $grey-light-bg;
  min-width: 30px;
  min-height: 30px;
  border: 2px solid $white;
  border-radius: 25px;
  color: $dark-black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
/* Pagination CSS */
.pagination-wrap {
  display: flex;
  align-items: center;
  // border-top: 1px solid $border-light;
  padding: 10px 0;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: calc(100% + 80px);
    height: 1px;
    background-color: $border-light;
  }
  p {
    margin-right: 20px;
    font-weight: normal;
    font-size: $font-14;
    line-height: 17px;
    color: $text-primary;
  }
  .item-count {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: $text-secondary-dark;
  }
  .select-wrap {
    margin-right: 20px;
    width: 70px;
    // .css-tlfecz-indicatorContainer{
    //   padding: 0;
    // }
    .select-box__indicator {
      padding: 0;
    }
  }
}
/* Tab nambar */
.common-tab-wrap {
  width: 100%;
  // padding: 24px 24px 0 40px;
  .tab-navbar {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    background-color: $white;
    border-bottom: 1px solid #DBE2F0;
    &.nav-pills {
      padding-left: 40px;
      .nav-link,
      .show {
        color: $text-secondary;
        font-size: 14px;

        &.active,
        & > .nav-link {
          color: $secondary;
          background-color: transparent;
          border-bottom: 2px solid $secondary;
          border-radius: 0;
          font-weight: 700;
        }
      }
    }
  }
  .tab-content {
    width: 100%;
    font-size: 14px;
    padding-top: 12px;
  }
}

.info-wrap-title {
  margin-bottom: 5px;
  padding-bottom: $padding-bottom-20;
  h5 {
    &.title {
      color: $badge-title-text;
      font-weight: 500;
      font-size: $font-15;
      line-height: 24px;
      display: flex;
      align-items: center;
      span {
        margin-right: 15px;
      }
    }
  }
}
.action-btn-wrap .right-align-dropdown {
  min-width: 13rem;
}

/* Data Table */
.datatable-wrap {
  // div{
  //   &:first-child{
  //     overflow-y: visible;
  //   }
  // }

  .common-dropdown {
    .dropdown-menu {
      min-width: 13rem;
      animation: none;

      &.right-align-dropdown {
        left: 0 !important;
        top: 0 !important;
      }
      &.bottom-end-dropdown {
        left: 0 !important;
        top: 16px !important;
        transform: translateX(-93%) !important;
      }
      .dropdown-item {
        .dropdown-icon {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        &:last-child {
          color: $text-archive;
        }
      }
    }
  }
  .rdt_Table {
    background-color: transparent;
  }
  .rdt_TableCol {
    padding: 8px 0px 8px 35px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $font-12;
    line-height: 24px;
    &:last-child {
      padding-right: 35px;
    }
    .rdt_TableCol_Sortable {
      &:hover {
        span {
          background: url('../../img/filter.svg');
          background-repeat: no-repeat;
          background-position: center center;
          font-size: 0;
          background-size: contain;
          width: 16px;
        }
      }
      span {
        opacity: 1;
        background: url('../../img/down-angle.svg');
        background-repeat: no-repeat;
        background-position: center center;
        font-size: 0;
        background-size: contain;
        width: 9px;
        margin-left: 5px;
      }
    }
  }
  .rdt_TableCell {
    padding: 8px 0px 8px 35px;
    &:last-child {
      padding-right: 35px;
    }
    div {
      &:first-child {
        overflow: inherit;
      }
      &.description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        h5 {
          font-weight: 500;
          font-size: $font-14;
          line-height: 24px;
          color: $table-head-text;
          .icon {
            margin-left: 5px;
          }
        }
        p {
          font-weight: normal;
          font-size: $font-14;
          line-height: 24px;
          color: $text-secondary-dark;
        }
      }
      &.location {
        color: $text-secondary-dark;
        font-weight: normal;
        font-size: $font-14;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.name-wrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .imgbox {
          border-radius: 50px !important;
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
      span {
        font-size: $font-14;
        line-height: 24px;
        color: rgba(21, 19, 57, 0.7);
        cursor: pointer;
        &.status {
          font-weight: 500;
        }
      }
    }
  }
  .rdt_TableHeader {
    background-color: transparent;
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: transparent !important;
    border-bottom: none;
  }
  .rdt_TableRow {
    border-bottom: none !important;
    margin-bottom: 5px;
    &:hover {
      box-shadow: 1px 1px 10px rgba(94, 94, 155, 0.35);
    }
  }
}
.avtar-wraper {
  display: flex;
  align-items: center;
  .imgbox {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }
  h6 {
    font-family: 'Manrope' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #252350;
  }
  span {
    font-weight: normal;
    font-size: $font-14;
    line-height: 24px;
    color: rgba(66, 63, 121, 0.6);
  }
}
/* Accordions */
.task-checkbox-list {
  &.multicheck-list {
    .card {
      border: none;
      margin-bottom: 15px;
      .card-header {
        padding: 0;
        margin-bottom: 0;
        background-color: transparent;
        border-bottom: none;
      }
      .card-body {
        padding: 0;
      }
    }
    .title {
      span {
        font-size: 14px;
        // &::before{
        //   content: '\e91c';
        // }
      }
      &.collapsed {
        span {
          font-size: 14px;
          //
          &::before {
            content: '\e921';
            // content: '\e91c';
          }
        }
      }
    }
  }
  .title-wrapper {
    padding: 25px 40px;
  }
  h6 {
    &.title {
      font-weight: 500;
      font-size: $font-18;
      line-height: 21px;

      cursor: pointer;
      color: #484585;
      display: flex;
      align-items: center;
      span {
        margin-right: 20px;
        font-size: 14px;
        &.icon-minus-line {
          font-size: 1.5px;
        }
        &::before {
          color: #484585;
        }
      }
    }
  }
  .check-list {
    li {
      padding: 24px 40px;
      border-bottom: 1px solid rgba(219, 226, 240, 0.5);
      .checkbox-wrap {
        position: relative;
        padding-right: 15px;
        .form-check {
          padding-left: 0;
        }
        .action-btn-wrap {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
  .checkbox-wrap {
    &.checkbox-with-action-btn {
      .iconbox {
        margin: 3px 20px 0 2px;
      }
    }
    &.add-info-wrap {
      position: relative;
      margin-bottom: 35px;
      .add-info-box {
        display: flex;
        .form-check-label {
          display: block;
          width: 20px;
          padding: 0 25px 0 0;
        }
        .form-group {
          margin-left: 15px;
          .form-control {
            border: none;
            border-radius: 0;
            padding: 0;
            min-height: auto;
            margin-bottom: 5px;
            width: 100%;
            min-width: 400px;
            height: 30px;
          }
          textarea {
            &.form-control {
              margin-bottom: 15px;
            }
          }
          .btn-add-info {
            margin-bottom: 5px;
          }

          .btn {
            font-weight: normal;
            text-transform: capitalize;
            background-color: transparent;
            border: none;
            color: $text-secondary-dark;
            box-shadow: none;
            span {
              &::before {
                color: $secondary;
              }
            }
            &:hover,
            &:focus {
              color: $secondary;
              background-color: transparent;
              border: none;
              box-shadow: none;
            }
          }
        }
        .uploaded-item-view {
          .btn-uploaded-cancel {
            background-color: rgba(143, 117, 25, 0.22);
            width: 20px;
            height: 20px;
            border-radius: 50px;
            margin-left: 5px;
            span {
              margin-right: 0;
              font-size: 9px;
              &::before {
                color: $white;
              }
            }
            &:hover,
            &:focus {
              color: $white;
              background-color: $secondary;
              border: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.add-new-checklist-wrap {
  background-color: $white;
  padding: 25px 40px;
  margin-bottom: 15px;
  border-radius: 4px;
  .form-control {
    &[readonly] {
      background-color: $white;
      border: none;
    }
  }
  .title-wrap {
    margin-bottom: 30px;
    .add-title-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      span {
        margin-right: 10px;
        &::before {
          color: $text-secondary-light;
        }
        &.icon-minus-line {
          font-size: 1.5px;
        }
      }
      .form-control {
        border-radius: 0;
        padding: 5px 10px;
        border-radius: 4px;
        min-height: auto;
        margin-bottom: 5px;
        width: 100%;
        min-width: 500px;
        height: 40px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: $font-18;
        line-height: 21px;
      }
    }
  }
  .add-info-wrap {
    .form-check {
      padding-left: 0;
      width: 20px;
    }
  }
  .form-control {
    &::placeholder {
      color: #9796ae;
    }
  }
  .add-info-box {
    position: relative;
    width: 100%;
    .action-btn-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .attachment-btn {
    position: relative;
    width: 120px;
    justify-content: flex-start;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    .file-upload-label {
      z-index: 1;
      cursor: pointer;
    }
    input {
      position: absolute;
      left: 0;
      opacity: 0;
      top: 0;
      height: 24px;
      cursor: pointer;
    }
  }
}

.horizontal-divider {
  opacity: 0.5;
  border-bottom: 1px solid #dbe2f0;
}

.fullscreen {
  &.fullscreen-enabled {
    .str-chat__modal-image__image {
      height: 100vh;
      width: auto;
    }
  }

  .react-images__frame {
    text-align: center;
  }

  .react-images__blanket {
    z-index: 1301;
  }

  .react-images__positioner {
    z-index: 1301;
  }
}
