
header {
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  &.blanknav {
    padding: 11px 0px;
  }
  .navbar {
    padding: 0;
    .navbar-nav {
      align-items: center;
      a {
        font-weight: normal;
        font-size: $font-14;
        line-height: 24px;
        text-align: center;
        padding: 19px 25px;
        color: $white;
        text-transform: capitalize;
        @include media(down, $breakpoint-md) {
          padding: 19px 20px;
        }
        &:hover,
        &:active {
          background-color: #534f9e;
        }
        &.active {
          background-color: #534f9e !important;
        }
      }
    }

    form {
      .search-wrap {
        height: 100%;
        align-items: center;
        left: 0;
        padding: 0;
        z-index: 1;
        background: #ffffff;
        .search-icon {
          display: block;
          cursor: pointer;
          @include transition-0-2s;
        }
        .x-icon {
          display: block;
          cursor: pointer;
          @include transition-0-2s;
        }
      }
    }
  }
  .noti-close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px;
    cursor: pointer;
  }
}

.profile-menu-wrapper {
  &:hover {
    background: #f2f5f9;
    border-radius: 6px;
  }
}

.bell-icon {
  &:hover {
    background: #f2f5f9;
    border-radius: 6px;
  }
}

.notification-dropdown {
  margin-right: 15px;
  .dropdown-toggle {
    position: relative;
    padding: 16px;
    &:hover {
      background: #f2f5f9;
      border-radius: 6px;
    }
    .dot-span {
      position: absolute;
      right: 5px;
      top: 5px;
      margin-right: 0;
    }
  }
  .notification-item {
    .iconbox {
      position: relative;
      .notify-dot {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .notification-title {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
      h6 {
        display: inline;
        font-weight: normal;
        font-size: $font-14;
        line-height: 20px;
        margin-bottom: 0;
      }
      .time {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 17px;
        color: $text-grey;
        font-size: $font-12;
        line-height: 14px;
        font-weight: normal;
        &::before {
          content: '';
          background-color: $dot-light-grey;
          width: 2px;
          height: 2px;
          position: absolute;
          left: 8px;
        }
      }
    }
    .notification-message-actions span {
      font-size: 13px;
      font-weight: 600;
      margin-right: 5px;
      display: inline-block;

      &.notification-dismiss {
        color: #666585;
      }
    }
  }
  .dropdown-menu {
    min-width: 390px;
    padding-top: 30px;
    height: 595px;
    top: 44px !important;

    .dropdown-header {
      .btn {
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-weight: 500;
        font-size: $font-12;
        line-height: 14px;
      }
    }
    .dropdown-info {
      padding: 0px 0;
      height: calc(595px - 140px);
      overflow-y: auto;
      overflow-x: hidden;
      .notification-time {
        padding-left: 60px;
        font-weight: 500;
        font-size: $font-14;
        line-height: 17px;
        padding-top: 15px;
        display: block;
      }
      .notification-item {
        padding: 15px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        align-items: flex-start;
        display: flex;
        .iconbox {
          flex-shrink: 0;
          margin-right: 15px;
          width: 24px;
          height: 24px;
          .notify-dot {
            position: absolute;
            right: -5px;
            bottom: -3px;
            background: #6868fe;
            width: 13px;
            height: 13px;
            border-radius: 25px;
            border: 3px solid white;
          }
        }

        .info {
          width: 100%;
        }
        .task-info {
          background-color: $light-grey-bg;
          padding: 12px;
          width: 100%;
          p {
            margin-bottom: 15px;
          }
          .link {
            font-weight: 500;
            font-size: $font-12;
            line-height: 14px;
          }
        }
      }
    }

    .dropdown-footer {
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px;
      .btn {
        box-shadow: none;
        font-size: 12px;
      }
    }
  }
}

.profile-dropdown {
  .dropdown-toggle {
    padding: 8px;
  }
  .dropdown-menu {
    overflow: hidden;
    top: 115%;
    .dropdown-item {
      font-weight: normal;
      text-transform: capitalize;
      font-size: $font-12;
      line-height: 24px;
      padding: 10px 15px;
      &.logout-nav-item {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        color: $danger;
        padding: 12px 15px;
      }
    }
  }
}

.search-icon {
  &:hover {
    cursor: pointer;
  }
}

input#search {
  border: none;
}

.input-with-icon.form-group {
  border: 1px solid #dbe2f0;
  box-sizing: border-box;
  border-radius: 3px;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

.nav-wrap {
  margin-top: 9px;
  margin-bottom: 9px;
}

.search-bar {
  flex-basis: 45%;
  .form-group {
    border-radius: 4px;
    .form-control {
      height: 38px;
    }
  }
}

.checklist-search {
  width: 310px;
  margin-right: 24px;

  & input {
    padding: 8.5px 14px 8.5px 50px !important;
    &::-webkit-input-placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.3);
    };
  };

  & svg {
    color: #0000008A,
  };

  .form-group.input-with-icon .input-left-icon {
    left: 14px;
  }

  .input-with-icon.form-group {
    border-color: rgba(0,0,0,0.23);
    border-radius: 4px;

    &:hover {
      border-color: #000;
    }

    &:focus {
      border-width: 2px;
      border-color: #6868fe;
    }
  }
}
