.communication-content-wrap {
  background-color: $white;

  .card-header {
    .archiveHeaderTitle {
      position: absolute;
      top: 14px;
      left: 80px;
    }
  }

  .card-body {
    .list-group {
      .list-group-item {
        .archiveChatMember {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: rgba(37, 35, 80, 0.5);
          display: block;
          position: absolute;
          margin-top: 24px;
          margin-left: 33px;
        }
      }
    }
  }

  .content-title-wrap {
    &.wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 24;
      padding-right: 24;
      min-height: 0;
    }

    border-bottom: 1px solid rgba(37, 35, 80, 0.1);
    height: 49px;
    .title-wrap {
      .added-members {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 0;
        }

        span {
          margin: 0;
        }
      }

      span {
        font-weight: normal;
        font-size: $font-12;
        line-height: 15px;
        color: $text-light-secondary;
      }

      .dropdown {
        .dropdown-toggle {
          color: $secondary;
          font-weight: normal;
          font-size: $font-12;
          line-height: 16px;
        }
      }
    }

    .title {
      font-size: $font-16;
      color: $default-color;
    }

    .actionbox {
      display: flex;
      align-items: center;

      .btn {
        span {
          font-size: 20px;

          &::before {
            color: $secondary;
          }
        }
      }
    }
  }

  .recommended-list-form {
    .searchbox {
      margin-bottom: 0;

      &.input-with-icon {
        .input-left-icon {
          left: 40px;

          &::before {
            color: #7571c5;
          }
        }
      }

      .form-control {
        border-radius: 0;
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 1px solid rgba(37, 35, 80, 0.1);
        padding: 20px 40px 20px 70px;
        height: 60px;
      }
    }

    .content-title-info {
      padding: 12px 40px;

      .title-wrp {
        .title {
          align-items: center;
          font-weight: normal;
          line-height: 24px;
          font-size: $font-16;
          color: $default-color;
        }

        span {
          font-weight: normal;
          font-size: $font-14;
          line-height: 17px;
          color: rgba(66, 63, 121, 0.5);
        }
      }

      .actionbox {
        .btn {
          padding: 0;
          font-weight: normal;

          span {
            margin: 0 0 0 10px;
          }
        }
      }
    }

    .recommended-list {
      padding: 10px 40px;
    }

    .btnbox {
      padding: 20px 40px;

      .btn {
        margin-left: 10px;
      }
    }
  }
}

.employee-avtar-item {
  border: 1px solid rgba(219, 226, 240, 0.5);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .custom-checkbox {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .avtar-wraper {
    .imgbox {
      width: 36px;
      height: 36px;
      margin-right: 20px;
    }

    .namebox {
      h6 {
        font-size: $font-16;
      }
    }
  }

  .form-check-label {
    &::before {
      content: '';
      display: inline-block;
      border: 1px solid $text-secondary;
      border-radius: 2px;
      background-color: $white;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: -7px;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }

  input {
    display: none;

    &:checked {
      & + .form-check-label {
        &::before {
          background-color: $text-secondary;
          position: absolute;
          left: 0;
          top: -7px;
        }

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 6px;
          top: -5px;
          width: 5px;
          height: 10px;
          border: 1px solid $white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}

.archived-msg-title-wrap {
  padding: 25px 75px;
}

.archived-msg-list {
  padding: 5px 45px;

  .msg-avtar-item {
    border-bottom: 1px solid rgba(219, 226, 240, 0.5);
    padding: 10px 70px 10px 30px;
    position: relative;

    .avtar-wraper {
      align-items: flex-start;

      .imgbox {
        width: 24px;
        height: 24px;
        margin-top: 3px;
      }
    }

    span {
      &.time {
        position: absolute;
        top: 10px;
        right: 0;
        font-weight: normal;
        font-size: $font-14;
        line-height: 25px;
        color: $text-secondary;
      }
    }
  }
}

.chat-content-wrap {
  z-index: 0;
  box-shadow: -1px 0px 0px rgba(44, 36, 232, 0.11);

  .archive-chat-msz {
    z-index: 999;

    .unarchive-btn {
      color: #6868fe;
      font-weight: 500;

      cursor: pointer !important;
    }

    position: absolute;
    bottom: 72px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-content: initial;
    background: white;
    padding: 10px;
    width: 70%;
    justify-content: center;
  }

  .content-title-wrap {
    .btn {
      margin-left: 30px;

      &.add-member-btn {
        color: $primary;
        text-transform: capitalize;
        font-weight: normal;

        span {
          margin-right: 8px !important;
          color: $secondary;
          font-size: 16px;
        }
      }
    }

    .actionbox {
      .btn {
        span {
          margin-right: 0;
        }
      }
    }

    .title-searchbox {
      .icon-search {
        &:before {
          color: #7571c5;
        }
      }
    }
  }

  .chat-box-form {
    position: relative;

    .str-chat__empty-channel {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 200px;
    }
    

    .msg-wrap {
      height: auto;
      flex-grow: 1;
      display: block;
      overflow: hidden;
      position: relative;

      .msg-outer {
        position: absolute;
        bottom: 0px;
        left: 0;
        padding: 20px 40px;
        max-height: 100%;
        overflow-y: auto;
      }

      .avtar-wraper {
        align-items: flex-start;
        margin-bottom: 35px;
        position: relative;
        width: 100%;

        &:hover {
          .action-btn-wrap {
            display: block;
          }
        }

        .imgbox {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        .name-time-wrap {
          h6 {
            margin-right: 10px;
            font-weight: normal;
            font-size: $font-16;
            line-height: 24px;
            color: rgba(37, 35, 80, 0.7);
            cursor: pointer;
          }

          span {
            font-weight: normal;
            font-size: $font-12;
            line-height: 16px;
            color: rgba(37, 35, 80, 0.5);
          }
        }

        &:last-child {
          margin-bottom: 10px;
        }

        p {
          font-weight: normal;
          font-size: $font-12;
          line-height: 12px;
          color: $text-primary;
        }

        .info-icon {
          color: $text-light-disabled;
        }

        .action-btn-wrap {
          display: none;
          height: 18px;

          div {
            display: flex;
          }

          button {
            padding: 0;
          }

          .dropdown-menu {
            .dropdown-item {
              padding: 5px 10px;
              line-height: 18px;
              font-size: $font-12;
            }
          }
        }

        &.sender {
          flex-direction: row-reverse;

          .imgbox {
            margin-right: 0;
            margin-left: 10px;
          }

          .name-time-wrap {
            flex-direction: row-reverse;

            h6 {
              margin-right: 0;
              margin-left: 10px;
            }
          }

          p {
            text-align: right;
          }

          .user-message {
            background: $primary-50;
            border-radius: 12px 0px 12px 12px;
            border: 1px solid $primary-100;
            padding: 8px;
            max-width: 70%;
            overflow-wrap: break-word;
            margin-top: 10px;

            p {
              line-height: 20px;

              .message-user-tag {
                background-color: #ffffff;
                color: $primary-main;
              }
            }

            img {
              border-radius: 20px;
            }
          }

          .namebox {
            span {
              margin-right: 4px;
            }
          }

          .dropdown-toggle {
            span {
              margin-top: 10px;
            }
          }
        }

        &.receiver {
          .user-message {
            margin-top: 10px;
            background: $primary-50;
            border-radius: 0px 12px 12px 12px;
            border: 1px solid $primary-200;
            padding: 8px;
            max-width: 70%;
            overflow-wrap: break-word;

            img {
              border-radius: 20px;
            }

            .message-user-tag {
              line-height: 20px;
            }
          }

          .namebox {
            span {
              margin-right: 2px;
            }
          }
        }

        .message-user-tag {
          border-radius: 90px;
          background: $primary-main;
          padding: 0px 4px;
          color: $white;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .alert-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        .badge {
          width: auto;
          border-radius: 4px;
          padding: 2px 15px;
          font-weight: normal;
          font-size: $font-14;
          line-height: 24px;
        }
      }

      .no-member-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding: 10px 15px;

        &::after {
          content: '';
          width: calc(100% + 30px);
          border-radius: 4px;
          height: 100%;
          position: absolute;
          left: -15px;
          background-color: #f7f9fd;
          z-index: -1;
        }

        .btn {
          text-transform: capitalize;

          span {
            margin: 0 0 0 10px;
          }
        }
      }
    }

    .type-msg-input-wrap {
      padding: 25px;
      position: relative;
      width: 100%;

      .form-group {
        margin-bottom: 0;

        .upload-file-wrap {
          label {
            cursor: pointer;
            position: relative;
            z-index: 11;
            margin-right: 35px;
            display: flex;
            align-items: center;

            span {
              font-size: 16px;
            }
          }

          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: -1;
            width: 20px;
          }
        }

        .btnbox {
          position: absolute;
          top: 12px;
          right: 15px;

          .send-btn {
            span {
              font-size: 16px;
              font-weight: bold;

              &::before {
                color: $secondary;
              }
            }
          }
        }
      }
    }
  }
}

.communication-sidenav-form {
  .nav-tabs {
    .nav-item {
      width: 50%;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      border-radius: 0;
      font-weight: 500;
      border: none;
      color: $text-light-secondary;
      text-align: center;
      opacity: 0.7;
      padding-top: 14px;
      padding-bottom: 14px;

      &.active {
        color: $primary-500;
        font-weight: 700;
        background-color: transparent;
        border-bottom: 2px solid $primary-500;
      }
    }
  }

  .communication-accordion {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 50px;
  }
}

.communication-layout-wrap {
  border-top: 1px solid #dbe2f0;
  height: 100%;
}
.communication-layout-wrap-cnt {
  height: 100%;
}
.str-chat__container {
  height: -webkit-calc(100vh - 130px);
}
