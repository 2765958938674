// Bootstrap overdies varibles File
@import 'helpers/variable';
// Custom Files
@import 'helpers/_import.scss';
@import 'modules/_all.scss';

$sidebar-bg-color: #252350 !default;
$sidebar-color: #a5a4c1 !default;
$sidebar-width: 265px !default;
$sidebar-collapsed-width: 72px !default;
$highlight-color: #ffffff !default;
$submenu-bg-color: #1c1b41 !default;
$submenu-bg-color-collapsed: #1c1b41 !default;
$icon-size: 24px !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';
@import 'react-day-picker-latest/dist/style.css';
@import 'react-day-picker/lib/style.css';

:root {
  // --rdp-cell-size: 40px;
  --rdp-accent-color: #6868fe;
  --rdp-background-color: #6868fe;
  // /* Switch to dark colors for dark themes */
  // --rdp-accent-color-dark: #6868fe;
  // --rdp-background-color-dark: #180270;
  // /* Outline border for focused elements */
  // --rdp-outline: 2px solid var(--rdp-accent-color);
  // /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid #6868fe;
  --toastify-toast-min-width: 185px;
  --toastify-toast-width: auto;
  --toastify-toast-min-height: 48px;
}

/* icomoon fonts */
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?jqy59n');
  src: url('../fonts/icomoon.eot?jqy59n#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?jqy59n') format('truetype'),
    url('../fonts/icomoon.woff?jqy59n') format('woff'),
    url('../fonts/icomoon.svg?jqy59n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

appcues-container {
  border-radius: 14px;
}

.icon-setting:before {
  content: '\e946';
  color: #6868fe;
}

.icon-remove:before {
  content: '\e945';
  color: #ef0d36;
}

.icon-mail:before {
  content: '\e944';
}

.icon-chat2:before {
  content: '\e943';
  color: #6868fe;
}

.icon-add-user:before {
  content: '\e942';
  color: #6868fe;
}

.icon-send:before {
  content: '\e941';
}

.icon-multi-dots:before {
  content: '\e940';
  color: #7e858e;
}

.icon-picture:before {
  content: '\e93f';
  color: #b7b77e;
}

.icon-minus-line:before {
  content: '\e91c';
  color: #8684b7;
}

.icon-bath:before {
  content: '\e93e';
  color: #8684b7;
}

.icon-clock-1:before {
  content: '\e93d';
  color: #8684b7;
}

.icon-left-arrow:before {
  content: '\e912';
  color: #7d78c9;
}

.icon-bed:before {
  content: '\e900';
  color: #7571c5;
}

.icon-calendar:before {
  content: '\e901';
  color: #8684b7;
}

.icon-chat:before {
  content: '\e902';
  color: #7571c5;
}

.icon-close:before {
  content: '\e903';
  color: rgba(0, 0, 0, 0.54);
  font-weight: 700;
}

.icon-component .path1:before {
  content: '\e904';
  color: rgb(0, 0, 0);
}

.icon-component .path2:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-delete:before {
  content: '\e906';
}

.icon-department:before {
  content: '\e907';
  color: #8684b7;
}

.icon-down-angle:before {
  content: '\e908';
  color: #636363;
}

.icon-up-angle:before {
  content: '\e911';
  color: #636363;
}

.icon-edit:before {
  content: '\e909';
  color: #6868fe;
}

.icon-error:before {
  content: '\e90a';
  color: #ff0b37;
}

.icon-filter:before {
  content: '\e90b';
  color: #6e6c92;
}

.icon-girl-user-icon:before {
  content: '\e90c';
  color: #7571c5;
}

.icon-green-tick:before {
  content: '\e90d';
  color: #2dca73;
}

.icon-grid:before {
  content: '\e90e';
  color: #8684b7;
}

.icon-info:before {
  content: '\e90f';
  color: #7571c5;
}

.icon-label:before {
  content: '\e910';
}

.icon-left-angle:before {
  content: '\e911';
  color: #7571c5;
}

.icon-like:before {
  content: '\e913';
  color: #fff;
}

.icon-list:before {
  content: '\e914';
  color: #6868fe;
}

.icon-lock:before {
  content: '\e915';
  color: #423f79;
}

.icon-minus:before {
  content: '\e916';
  color: #6868fe;
}

.icon-more-horizontal:before {
  content: '\e917';
  color: #7571c5;
}

.icon-msg-icon-round-blue .path1:before {
  content: '\e918';
  color: rgb(117, 113, 197);
}

.icon-msg-icon-round-blue .path2:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-msg-icon-round-blue .path3:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(117, 113, 197);
}

.icon-notification:before {
  content: '\e91b';
  color: #fff;
}

.icon-pause:before {
  content: '\e91d';
  color: #7571c5;
}

.icon-pdf:before {
  content: '\e91e';
  color: #d73d3d;
}

.icon-image:before {
  content: '\e92e';
  color: #d73d3d;
}

.icon-pin:before {
  content: '\e91f';
  color: #7571c5;
}

.icon-plush-icon-white:before {
  content: '\e920';
  color: #fff;
}

.icon-plush:before {
  content: '\e921';
  color: #252350;
}

.icon-recurring .path1:before {
  content: '\e922';
  color: rgb(0, 0, 0);
}

.icon-recurring .path2:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-report-issue .path1:before {
  content: '\e924';
  color: rgb(250, 100, 67);
}

.icon-report-issue .path2:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-report-issue .path3:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-right-angle:before {
  content: '\e927';
  color: #6e6c92;
}

.icon-right-arrow:before {
  content: '\e928';
  color: #666585;
}

.icon-rooms:before {
  content: '\e929';
  color: #8684b7;
}

.icon-rush-room .path1:before {
  content: '\e92a';
  color: rgb(0, 0, 0);
}

.icon-rush-room .path2:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-save:before {
  content: '\e92c';
  color: #fff;
}

.icon-search-Icon-light:before {
  content: '\e92d';
  color: #7d78c9;
}

.icon-search:before {
  content: '\e92e';
  color: #252350;
}

.icon-slide-option-icon:before {
  content: '\e92f';
  color: #7571c5;
}

.icon-tag:before {
  content: '\e930';
  color: #6868fe;
}

.icon-task-icon-pink .path1:before {
  content: '\e931';
  color: rgb(195, 113, 197);
}

.icon-task-icon-pink .path2:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-task .path1:before {
  content: '\e933';
  color: rgb(255, 132, 68);
}

.icon-task .path2:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-tick-white-round .path1:before {
  content: '\e935';
  color: rgb(255, 255, 255);
}

.icon-tick-white-round .path2:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(45, 202, 115);
}

.icon-user-icon:before {
  content: '\e937';
  color: #8684b7;
}

.icon-user:before {
  content: '\e938';
  color: #fff;
}

.icon-vip .path1:before {
  content: '\e939';
  color: rgb(0, 0, 0);
}

.icon-vip .path2:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-watch:before {
  content: '\e93b';
  color: #8684b7;
}

.icon-action-dots:before {
  content: '\e93c';
  color: #7e858e;
}

.menu-icon-task:before {
  content: url('../img/menu-task.svg');
}

.menu-icon-task-active:before {
  content: url('../img/menu-task-active.svg');
}

.menu-icon-checklist:before {
  content: url('../img/menu-checklist.svg');
}

.menu-icon-checklist-active:before {
  content: url('../img/menu-checklist-active.svg');
}

.menu-icon-messaging:before {
  content: url('../img/menu-messaging.svg');
}

.menu-icon-messaging-active:before {
  content: url('../img/menu-messaging-active.svg');
}

.menu-icon-reporting:before {
  content: url('../img/menu-reporting.svg');
}

.menu-icon-reporting-active:before {
  content: url('../img/menu-reporting-active.svg');
}
.menu-icon-configuration:before {
  content: url('../img/menu-configuration.svg');
}

.menu-icon-configuration-active:before {
  content: url('../img/menu-configuration-active.svg');
}

.icon-sidebar-toggle:before {
  content: url('../img/sidebar-toggle.svg');
}

.icon-arrow-down:before {
  content: url('../img/arrow-down.svg');
}

.icon-round-plus:before {
  content: url('../img/round-plus.svg');
}

.icon-filter-default:before {
  content: url('../img/filter-default.svg');
}

.icon-filter-selected:before {
  content: url('../img/filter-selected.svg');
}

.icon-priority-critical:before {
  content: url('../img/priority-critical.svg');
}

.icon-priority-high:before {
  content: url('../img/priority-high.svg');
}

.icon-priority-medium:before {
  content: url('../img/priority-medium.svg');
}

.icon-priority-low:before {
  content: url('../img/priority-low.svg');
}

.icon-priority-none:before {
  content: url('../img/priority-none.svg');
}

.icon-recurring-v2:before {
  content: url('../img/recurring-v2.svg');
}

.icon-overdue-v2:before {
  content: url('../img/overdue-v2.svg');
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  letter-spacing: -0.03px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: url(#{$assetPath}/confirm-cancel.jpg);
  background-color: $hotel-admin-bg;
  color: $primary;

  @include media(down, $breakpoint-lg) {
    font-size: $font-14;
  }
}

// Begin: Links
a {
  text-decoration: none;
  font-size: $font-size-base;
  cursor: pointer;
  outline: 0;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

.bold600 {
  font-weight: 600 !important;
}

.bold-500 {
  font-weight: 500 !important;
}

// Begin: helper Classe
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  font-size: 14px;
  line-height: 24px;
  color: $text-secondary;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

// Custom Scroll with CSS only Supported Browser Chrome, Safari and Firefox
* {
  scrollbar-color: rgba($black, 0.3) transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($black, 0.3);
  border-radius: 12px;
}

:focus {
  outline: none;
  box-shadow: none;
}

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

em {
  font-style: normal;
}

/* ============================================================================= Commen style Start */

/* Container */
.sm-container {
  max-width: 580px;
  margin: 0 auto;
  padding: 15px;
}

.inline-block {
  display: inline-block;
}

/* Margin class  */
.mb-50 {
  margin-bottom: $margin-bottom-50;
}

.mb-45 {
  margin-bottom: $margin-bottom-45;
}

.mb-40 {
  margin-bottom: $margin-bottom-40;
}

.mb-35 {
  margin-bottom: $margin-bottom-35;
}

.mb-30 {
  margin-bottom: $margin-bottom-30;
}

.mb-25 {
  margin-bottom: $margin-bottom-25;
}

.mb-20 {
  margin-bottom: $margin-bottom-20;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-15 {
  margin-bottom: $margin-bottom-15;
}

.mb-10 {
  margin-bottom: $margin-bottom-10;
}

.mb-8-p {
  margin-bottom: 8px;
}

.mb-5 {
  margin-bottom: $margin-bottom-5;
}

.mb-5-p {
  margin-bottom: 5px;
}

.mb-4-p {
  margin-bottom: 4px;
}

.mt-50 {
  margin-top: $margin-top-50;
}

.mt-45 {
  margin-top: $margin-top-45;
}

.mt-40 {
  margin-top: $margin-top-40;
}

.mt-35 {
  margin-top: $margin-top-35;
}

.mt-30 {
  margin-top: $margin-top-30;
}

.mt-25 {
  margin-top: $margin-top-25;
}

.mt-20 {
  margin-top: $margin-top-20;
}

.mt-16 {
  margin-top: 16px;
}

.mt-15 {
  margin-top: $margin-top-15;
}

.mt-10 {
  margin-top: $margin-top-10;
}

.mt-5 {
  margin-top: $margin-top-5;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-36 {
  margin-left: 36px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-6-p {
  margin-right: 6px;
}

.mr-5-p {
  margin-right: 5px;
}

.mr-9-p {
  margin-right: 9px;
}

.mr-10 {
  margin-right: $margin-right-10 !important;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-36 {
  margin-right: 36px;
}

.mr-50 {
  margin-right: 50px;
}

.m-5-p {
  margin: 5px;
}

.m-8-p {
  margin: 8px;
}

.m-10-p {
  margin: 10px;
}

.m-11 {
  margin: 11px;
}

.m-8-16 {
  margin: 8px 16px;
}

.m-12-40 {
  margin: 12px 40px;
}

.m-20 {
  margin: 20px;
}

.m-24 {
  margin: 24px;
}

/* Padding class  */

.px-24 {
  padding: 0px 24px;
}

.px-40 {
  padding: 0px 40px;
}

.p-12-20 {
  padding: 12px 20px;
}

.p-10-15 {
  padding: 10px 15px;
}

.p-8-16 {
  padding: 8px 16px;
}

.p-10-20 {
  padding: 10px 20px;
}

.p-8-20 {
  padding: 8px 20px;
}

.p-8-10 {
  padding: 8px 10px;
}

.p-6-12 {
  padding: 6px 12px;
}

.p-6-8 {
  padding: 6px 8px;
}

.p-0-10 {
  padding: 0px 10px;
}

.p-18-32 {
  padding: 18px 32px !important;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-40 {
  padding: 40px;
}

.p-6-p {
  padding: 6px;
}

.p-8-p {
  padding: 8px;
}

.p-16-p {
  padding: 16px;
}

.pb-50 {
  padding-bottom: $padding-bottom-50;
}

.pb-45 {
  padding-bottom: $padding-bottom-45;
}

.pb-40 {
  padding-bottom: $padding-bottom-40;
}

.pb-35 {
  padding-bottom: $padding-bottom-35;
}

.pb-30 {
  padding-bottom: $padding-bottom-30;
}

.pb-25 {
  padding-bottom: $padding-bottom-25;
}

.pb-20 {
  padding-bottom: $padding-bottom-20;
}

.pb-15 {
  padding-bottom: $padding-bottom-15;
}

.pb-10 {
  padding-bottom: $padding-bottom-10;
}

.pb-5 {
  padding-bottom: $padding-bottom-5;
}

.pt-50 {
  padding-top: $padding-top-50;
}

.pt-45 {
  padding-top: $padding-top-45;
}

.pt-40 {
  padding-top: $padding-top-40;
}

.pt-35 {
  padding-top: $padding-top-35;
}

.pt-30 {
  padding-top: $padding-top-30;
}

.pt-25 {
  padding-top: $padding-top-25;
}

.pt-20 {
  padding-top: $padding-top-20;
}

.pt-15 {
  padding-top: $padding-top-15;
}

.pt-10 {
  padding-top: $padding-top-10;
}

.pt-5 {
  padding-top: $padding-top-5;
}

.pl-24 {
  padding-left: 24px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-30 {
  padding-right: 30px;
}

.height-56 {
  height: 56px;
}

.min-width-20-pc {
  min-width: 20%;
}

.min-width-auto {
  min-width: auto !important;
}

.min-width-135 {
  min-width: 135px;
}

.min-width-180 {
  min-width: 180px;
}

.min-width-200 {
  min-width: 200px;
}

.min-height-0 {
  min-height: 0 !important;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.width-pct-0 {
  width: 0%;
}

.width-pct-4 {
  width: 4%;
}

.width-pct-5 {
  width: 5%;
}

.width-pct-6 {
  width: 6%;
}

.width-pct-7 {
  width: 7%;
}

.width-pct-8 {
  width: 8%;
}

.width-pct-9 {
  width: 9%;
}

.width-pct-10 {
  width: 10%;
}

.width-pct-11 {
  width: 11%;
}

.width-pct-12 {
  width: 12%;
}

.width-pct-13 {
  width: 13%;
}

.width-pct-14 {
  width: 14%;
}

.width-pct-15 {
  width: 15%;
}

.width-pct-18 {
  width: 18%;
}

.width-pct-28 {
  width: 28%;
}

.width-pct-45 {
  width: 45%;
}

.width-pct-60 {
  width: 60%;
}

.width-pct-70 {
  width: 70%;
}

.width-pct-85 {
  width: 85%;
}

/* Background Css */
.hotel-manegemen-bg {
  background-color: #e5e5e5;
}

/* Text css */
.text-danger {
  color: $danger;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

/* Radius */
.radius4 {
  border-radius: 4px !important;
}

.radius15 {
  border-radius: 15px !important;
}

.radius50 {
  border-radius: 25px !important;
}

.radius50 {
  border-radius: 25px !important;
}

/* Alert msg */
.alert-msg {
  max-width: 360px;
  width: 100%;
  border-radius: 6px;
  padding: 16px 70px 16px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  h6 {
    color: $white;
  }

  p {
    margin-bottom: 0;
  }
  &.error-msg-wrap {
    background: $pink-bg;
    position: relative;
    margin-top: 20px;

    p {
      position: relative;
      left: 30px;
      top: -2px;
      color: red;
    }

    &::before {
      content: '';
      background: url('../img/error.png');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      width: 18px;
      height: 18px;
    }
  }
  &.success-msg-wrap {
    background: $green-gradiant;
    position: relative;

    &::before {
      content: '';
      background: url('../img/success-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  &.task-created-alert {
    width: 238px;
    position: absolute;
    right: 30px;
    bottom: 30px;
    margin: 0;
    z-index: 1;
  }

  &.inprogress-msg-wrap-alert {
    background: $orange-gradiant;
    position: relative;

    &::after {
      content: '';
      background: url('../img/time-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  &.completed-msg-wrap-alert {
    background: $green-bg;
    position: relative;

    &::after {
      content: '';
      background: url('../img/complate-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }
}

/*toaster  */
.Toastify__toast-container {
}

.automation-trigger {
  background: white !important;
  border-top: 4px solid #FF8F00 !important;
  border-radius: 8px !important;

  .Toastify__toast-body {
    margin-left: 5px !important;
    align-items: start !important;
  
    .Toastify__toast-icon {
      display: block !important;
    }
  }

}

.Toastify__toast {
  width: 100%;
  border-radius: 6px;
  // padding: 15px 70px 10px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;



  .Toastify__toast-body {
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    margin-left: 50px;
    position: relative;
  }

  h6 {
    color: $white;
  }

  p {
    margin-bottom: 0;
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    display: none;
  }

  &.Toastify__toast--success {
    background: $green-bg;
    position: relative;
    color: $white;

    &.task-created {
      max-width: 185px;
      margin: auto;
      height: 48px;
    }

    &::before {
      content: '';
      background: url('../img/success-bg-icon2.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 20px;
      bottom: 0;
      top: 13px;
      width: 23px;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  &.task-created-alert {
    width: 238px;
    position: absolute;
    right: 30px;
    bottom: 30px;
    margin: 0;
    z-index: 1;
  }

  &.inprogress-msg-wrap-alert {
    background: $orange-gradiant;
    position: relative;

    &::after {
      content: '';
      background: url('../img/time-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  &.completed-msg-wrap-alert {
    background: $green-gradiant;
    position: relative;

    &::after {
      content: '';
      background: url('../img/complate-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  &.Toastify__toast--error {
    background: $red-gradiant;
    position: relative;
    color: $white;

    &::after {
      content: '';
      transform: rotate(-180deg);
      background: url('../img/complate-bg-icon.svg');
      background-repeat: no-repeat;
      background-position: 100% 0%;
      background-size: contain;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
    }

    p {
      color: $white;
      font-weight: 600;
      font-size: $font-14;
      line-height: 24px;
    }
  }
  
  &.Toastify__toast--info {
    background: #039BE5;
    color: #fff;

    .Toastify__toast-body {
      margin-left: 5px !important;
      align-items: start !important;
      .Toastify__toast-icon {
        display: block !important;
        svg {
          fill: #fff
        }
      }
    } 
  }
  
}
.rc-time-picker-panel {
  z-index: 1303 !important;
}
/* Link */
.secondary-link {
  color: $secondary !important;

  &:hover {
    text-decoration: underline;
  }
}

/* text */
.primary-text {
  color: $primary !important;
}

.secondary-text {
  color: $secondary !important;
}

.grey-text {
  color: $grey !important;
}

.white-text {
  color: white !important;
}

.font-16-700 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.font-10-700 {
  font-size: 10px;
  font-weight: 700;
}

.font-12-500 {
  font-size: 12px;
  font-weight: 500;
}

.font-13-500 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

/* Dots style */
.dot-span {
  width: 9px;
  height: 9px;
  border-radius: 100%;

  &.notify-dot {
    border: 2px solid $white;
    background-color: $secondary;
  }

  &.orange-dot {
    background-color: $secondary;
    border: 2px solid #28255c;
  }

  &.dot-active {
    background-color: $dot-active;
    display: inline-block;
  }

  &.dot-inactive {
    background-color: $dot-inactive;
    display: inline-block;
  }

  &.dot-open {
    background-color: $dot-unassigned;
    display: inline-block;
    border: 4px solid #3b8ef0;
    box-sizing: border-box;
    border-radius: 34px;
  }

  &.dot-on-hold {
    background-color: $dot-on-hold;
    display: inline-block;
  }

  &.dot-in-progress {
    background-color: $dot-in-progress;
    display: inline-block;
  }

  &.dot-paused {
    background-color: $dot-paused;
    display: inline-block;
  }

  &.dot-incomplete {
    background-color: $dot-incomplete;
    display: inline-block;
  }

  &.dot-complete {
    background-color: $dot-complete;
    display: inline-block;
  }
}

$status-unassigned: #3b8ef0;
$status-not-started: #f58500;
$status-in-progress: #04b86c;
$status-paused: #64636d;
$status-incomplete: #ad3704;
$status-complete: #04a3ad;

$status-light-grey: #c4c4c4;
$status-active: #2dca73;
$status-inactive: #d7d7e0;

.status-light-gre {
  color: $status-light-grey !important;
}

.status-active {
  color: $status-active !important;
}

.status-inactive {
  color: $status-inactive !important;
}

.status-unassigned {
  color: $status-unassigned !important;
}

.status-not-started {
  color: $status-not-started !important;
}

.status-in-progress {
  color: $status-in-progress !important;
}

.status-paused {
  color: $status-paused !important;
}

.status-incomplete {
  color: $status-incomplete !important;
}

.status-complete {
  color: $status-complete !important;
}

/* Animation */
@keyframes slideIn {
  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0rem);
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

/* border dividers */
.dividers {
  height: 1px;
  width: 100%;
  background-color: $border-light;
  margin: 15px 0;
}

/* badges */
.badge {
  padding: 2px 8px 2px 8px;
  display: flex;
  align-items: center;
  display: inline-block;
  border-radius: 50px;
  height: auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $default-color;
  position: relative;
  margin: 0 3px 3px 0;

  .icon-close {
    width: 23px;
    height: 23px;
    border-radius: 50px;
    padding: 2px 35px 2px 8px;
    background-color: $white;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::before {
      color: $primary;
    }
  }

  &.badge-light {
    background-color: $dark-grey-bg;
  }
}

.splash-center,
.login-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.btnCenter {
  margin: 0 auto;
}

.centerstorypoint {
  display: flex;
  justify-content: center;
}

.tdcenter {
  text-align: center;
}

.passordshowicon {
  position: absolute;
  right: 0.75rem;
  top: 2.0625rem;
  font-size: 1.25rem;
  color: var(--text-color);
  opacity: 0.5;
  cursor: pointer;
}

.userProfileActionText {
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #666585 !important;
}

.pageChangeUserDropdown {
  border: 1px solid #2d2c58;
  color: #252350;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dot-span.dot-danger {
  background-color: #e73d3d;
  display: inline-block;
}

.fontnormal {
  font-weight: 400;
}

.rs-input-number {
  .rs-input {
    background: #ffffff;
    border-radius: 4px;
    height: 40px;
    color: #252350;
    font-size: 16px;
    width: 28px;
  }

  .rs-input-number-touchspin-up {
    float: right;
    background: #ffffff;
    border: 1px solid #a7bcd9;
    border-radius: 4px;
    height: 40px;
    color: #252350;
    font-size: 16px;
  }

  .rs-input-number-touchspin-down {
    float: left;
    background: #ffffff;
    border: 1px solid #a7bcd9;
    border-radius: 4px;
    height: 40px;
    color: #252350;
    font-size: 16px;
  }
}

.selectbox-with-icon {
  .select-wrap {
    width: 180px;
  }
}

.multi-rooms-wrap,
.task-assignment {
  .select-wrap {
    width: 100%;

    // .select-box__control {
    //   border: none;
    // }
    .select-box__multi-value {
      border-radius: 15px;
    }
  }
}

.title-textarea-wrap {
  .error {
    color: $danger;
    font-size: 12px;
  }
}

.errorTask {
  color: $danger;
  font-size: 12px;
}

.css-1rhbuit-multiValue {
  background-color: #e9e9f4 !important;
}

.select-box__multi-value__remove {
  width: 23px;
  height: 23px;
  border-radius: 50px !important;
  font-weight: 100;
  background-color: #ffffff;
}

.select-box__multi-value__remove {
  &:hover {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
}

.rc-time-picker-clear-icon {
  margin-left: 6px !important;
}

.rc-time-picker-input {
  width: 85px !important;
}

.errorchecklistTitle {
  margin-top: -27px;
  margin-left: 23px;
  padding-bottom: 27px;
  color: #ef0d36;
  font-size: 12px;
}

.div-button {
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: #252350;
  padding: 0.7rem;
}

.error {
  color: #ef0d36;
  font-size: 12px;
}

.isEdited {
  font-size: 10px !important;
  color: gray !important;
}

a {
  &.disabled {
    cursor: not-allowed !important;
    color: gray !important;
    pointer-events: none;
  }
}

.msz-search-user {
  cursor: pointer;
}

.fw-500 {
  font-weight: 500;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.secondary-grey {
  color: #534f9e;
}

@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.xenia-logo-blink {
  animation: myAnim 4s ease 0s infinite normal forwards;
}

.app {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .main {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.sidebar-menu {
  font-size: 14px !important;
  font-family: Manrope;
  line-height: 24px;
  margin-left: 22px;
  margin-right: 22px;
}

.menu-active {
  background: #393763;
  border-radius: 6px;
}

.pro-sub-menu {
  &:hover {
    background: #1c1b41;
    border-radius: 6px;
  }
}

.pro-sidebar {
  height: auto;
}

span.pro-icon-wrapper {
  line-height: 0 !important;
}

.pro-sidebar-header {
  border-bottom: 0 !important;
}

.pro-inner-item {
  padding: 8px 12px !important;

  &:hover {
    background: #1c1b41;
    border-radius: 6px;
  }
}

li.pro-menu-item {
  margin-bottom: 4px;

  &.active {
    background: #393763;
    border-radius: 6px;
    font-weight: 500;
  }
}

.pro-sub-menu.open .pro-menu-item.active {
  background: #1c1b41;
  border: 0;
}

.pro-sidebar.collapsed .sidebar-menu {
  margin-left: 12px;
  margin-right: 12px;
}

li.pro-menu-item.pro-sub-menu {
  margin-left: 22px;
  margin-right: 22px;
}

li.pro-menu-item.pro-sub-menu.open > .pro-inner-item {
  background: #393763;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
}

.pro-sidebar.collapsed .pro-sub-menu {
  margin-left: 12px;
  margin-right: 12px;
}

.align-i-center {
  align-items: center;
}

.main-header {
  position: sticky;
  align-self: flex-start;
  top: 0;
  height: 56px;
  padding: 10px 20px;

  .hdr-right {
    width: calc(100% - 310px);
  }

  .chat-searchbox {
    position: initial;
    background: #ffffff;
    border: 1px solid #d8dadd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    z-index: 9;
    width: calc(100% - 175px);
    margin-left: 13px;
    width: 310px;
    height: 42px;
  }
}

.color-unassigned {
  color: $status-unassigned;
}

.color-not-started {
  color: $status-not-started;
}

.color-in-progress {
  color: $status-in-progress;
}

.color-paused {
  color: $status-paused;
}

.color-incomplete {
  color: $status-incomplete;
}

.color-complete {
  color: $status-complete;
}

.task-filter-wrapper {
  margin: 30px 20px;
  letter-spacing: 0.01em;

  .filter-title {
    color: #8e8cac;
    text-transform: uppercase;
  }

  .form-check-label {
    line-height: 20px;
    color: #252350;
  }
}

.primary-button {
  background: #6868fe;
  color: #ffffff;
  font-weight: 500;
  box-shadow: 0px 2px 3px rgba(240, 87, 8, 0.24);
  border-radius: 4px;

  &:hover {
    background: #ef7536;
    box-shadow: 0px 4px 8px rgba(240, 87, 8, 0.24);
  }
}

.clear-button {
  color: #9291a7;
}

.vertical-divider {
  opacity: 0.3;
  color: #000000;
  font-size: 12px;
}

.chat-round-button {
  background: #6868fe;
  border-radius: 73px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #4e48fa;
  }
}

.cancel-button {
  &:hover {
    background: #f2f5f9;
    border-radius: 4px;
  }
}

.task-dates {
  justify-content: space-between;
  font-size: 11px !important;
  color: #666584;
}

.assignee-model {
  max-height: 400px;
  overflow: scroll;
}

.v2-orange-text {
  color: $v2-orange;
}

.v2-red-text {
  color: $v2-red;
}

.side-unread-message-bubble {
  position: absolute;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: block;
  text-align: center;
  background: #6868fe;
  color: #ffffff;
  top: 0px;
  border-radius: 50%;
  width: 20px;
  right: 4px;
  height: 20px;
}

.message-bubble-expanded-sidebar {
  margin-top: 10px;
  margin-right: 5px;
}

.date-time-picker-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
}

.secondary-bg {
  background: #ececf2;
}

.secondary-hover {
  &:hover {
    background: #f2f5f9;
    border-radius: 4px;
  }
}

.x-divider {
  border-bottom: 1px solid #dbe2f0;
}

.rdp-button {
  &:hover {
    color: #ffffff !important;
  }

  &:focus {
    color: #ffffff !important;
  }
}

.rdp-caption_label {
  font-weight: 400;
  font-size: 18px;
}

.picker-input {
  font-size: 14px;
}

.pointer-cursor {
  cursor: pointer;
}

.comments-list {
  overflow-y: auto;
  padding-bottom: 30px;
}

.task-comments {
  .str-chat__container {
    justify-content: flex-end;
  }
  .str-chat__input-flat-wrapper {
    margin-bottom: 1rem !important;
  }
}

.border-radius-16-px {
  border-radius: 16px;
}

.border-radius-15-px {
  border-radius: 15px;
}

.border-radius-12-px {
  border-radius: 12px;
}

.download-app-container {
  display: flex;
  flex-direction: column;

  .header {
    font-weight: 600;
    font-size: 18px;
    color: #666584;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
}

.font-weight-700 {
  font-weight: 700;
}

.push {
  margin-left: auto;
}

.suggestion-header {
  font-weight: 700;
  font-size: 16px;
}

.checklist-icon-container {
  padding: 9px;
  border-radius: 4px;
}

.MuiLinearProgress-bar1Determinate {
  background-color: #58c363 !important;
}

.dot-divider {
  color: rgba(0, 0, 0, 0.12);
  line-height: 16px;
  font-size: 24px;
  margin-left: 12px;
  margin-right: 12px;
}

.preview-checklist-modal {
  .modal-dialog {
    .modal-body {
      padding: 0px;
    }
  }
}

.modal-x-button {
  position: absolute;
  top: 25px;
  right: 25px;
}

.disablePointerEvents {
  pointer-events: none;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

.labelled-outline {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: white;
  &:hover {
    border-color: #000000;
  }
  &:focus-within {
    border-color: #6868fe;
    border-width: 2px;
  }
  legend {
    display: block;
    width: auto;
    padding: 0;
    margin-bottom: 0 !important;
    line-height: 14px;
    color: inherit;
    white-space: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 6px;
    margin-left: 16px;
    &:focus-within {
      color: #6868fe;
    }
  }
}

.draft-attachments {
  color: #666584;
}

.grey-pill {
  color: #252350;
  background-color: #f2f5f9;
}

.purple-pill {
  color: #252350;
  background-color: #ebeaff;
}

.white-pill {
  color: #252350;
  background-color: #ffffff;
}

.item-attachments-header {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.checklist-log-header {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px !important;
  font-weight: 500 !important;
  background-color: #ffffff;
}

.checklist-logs-table {
  .ag-theme-material {
    --ag-header-background-color: #ffffff;
    --ag-header-foreground-color: rgba(0, 0, 0, 0.87);
    --ag-material-accent-color: #6868fe;
    --ag-checkbox-unchecked-color: rgba(0, 0, 0, 0.3);
    --ag-cell-horizontal-padding: 16px;
    --ag-list-item-height: 80px;
  }

  .MuiAvatar-rounded.MuiAvatar-colorDefault {
    color: #6868fe !important;
    font-size: 16px;
    font-weight: 500;
    background-color: #f2f3f4 !important;
  }

  .MuiAvatar-img {
    border-radius: 4px;
  }
}

.modal-dialog#preview-checklist-modal,
#public-checklist-preview {
  max-width: 930px;
}

.modal-dialog#create-task-info {
  max-width: 1200px;
}

.primary-border {
  border: 1px solid #6868fe !important;
}

#step-type-modal {
  .modal-content {
    border-radius: 16px;
  }
}

.MuiTooltip-tooltip {
  max-width: none !important;
  background: #424242 !important;
}
