/* ========================================================================== Modals */
.modal-backdrop {
  z-index: 1202;
}
.bottom-end-dropdown {
  left: 0 !important;
  top: 16px !important;
  transform: translateX(-140px) !important;
  min-width: 13rem;
  animation: none !important;
}
.common-modal {
  z-index: 1301;
  &.add-member-model {
    .modal-body {
      height: calc(100vh - 200px) !important;
      overflow: hidden;

      .form-body {
        height: calc(100vh - 237px) !important;
        overflow: auto;

        .list {
          &.add-item-list {
            max-height: inherit !important;
          }
        }
      }
    }
  }

  &.md-modal {
    .modal-dialog {
      max-width: 695px;
    }
  }

  &.modal-right {
    .icon-left-angle {
      font-size: 16px !important;
    }

    .modal-header {
      .modal-title {
        font-weight: normal;
        font-size: $font-16;
        line-height: 24px;
        color: rgba(37, 35, 80, 0.5);
      }

      .close {
        display: block;
        font-size: 14px;
        padding: 5px;
        opacity: 1;

        span {
          &::before {
            color: $text-primary;
          }
        }
      }
    }

    .modal-dialog {
      position: fixed;
      margin: auto;
      max-width: 620px;
      width: 100%;
      right: 0;
      bottom: 0;
      height: auto;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
    }

    &.fade {
      &.show {
        .modal-dialog {
          right: 50px;
          left: auto;
        }
      }

      .modal-dialog {
        right: 0px;
        bottom: 0;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }
    }
  }

  .task-description-form {
    .title-textarea-wrap {
      .form-control {
        height: 57px;
        min-height: auto;
        resize: none;
      }
    }
  }
  .task-description-form {
    .title-input-wrap {
      .form-control {
        height: 40px;
        min-height: auto;
        resize: none;
      }
    }
  }
  .modal-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
  }

  &.change-password-modal {
    .modal-header {
      border-bottom: none;

      .close {
        display: none;
      }
    }
  }

  .modal-header {
    padding: 30px 30px 0 30px;
    border-bottom: none;
    position: relative;

    .close {
      display: none;
    }
  }

  .modal-body {
    padding: 30px;
  }

  .btnbox {
    .btn {
      margin-left: 10px;
    }
  }

  &.add-edit-user-modal {
    form {
      &.add-edit-user-form {
        .content {
          display: flex;

          .img-wrap {
            width: 130px;
            margin-right: 35px;
            flex-shrink: 0;

            .imgbox {
              width: 130px;
              height: 130px;
              border-radius: 5px;
              position: relative;

              img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
              }
            }
          }

          .imgupload-btn-wrap {
            position: relative;

            .form-file-label {
              cursor: pointer;
              position: relative;
              z-index: 1;
              border: 1px solid $text-secondary;
              box-sizing: border-box;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $text-secondary;
              font-weight: 500;
              font-size: $font-15;
              line-height: 24px;
              text-transform: capitalize;
              padding: 5px 10px;
              background-color: transparent;

              &:hover {
                background-color: $text-secondary;
                color: $white;
              }
            }

            .form-control-file {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              height: 100%;
              cursor: pointer;
              z-index: 0;
            }
          }
        }

        .form-group {
          margin-bottom: $margin-bottom-10;
        }

        .assign-admin-rights-btnbox {
          background-color: $warning-light-bg;
          border-radius: 4px;
          padding: 10px 15px;

          p {
            color: $text-dark-brown;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
          }

          .btn {
            flex-shrink: 0;
            margin-left: 15px;
            padding: 0;
          }
        }
      }
    }
  }

  &.add-task-modal {
    .modal-header {
      position: relative;
      padding: 36px 36px 0 36px;
      margin-bottom: 18px;

      .modal-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #252350;
      }

      .btnbox {
        position: relative;

        .close {
          bottom: auto;
          top: 0;
          right: 0;
        }
      }

      .close {
        position: absolute;
        right: 25px;
        bottom: 0px;
        background-image: url('../../img/CreateTaskClose.svg') !important;
        background-repeat: no-repeat;
        background-size: cover;
        width: 15px;
        height: 15px;
        opacity: 1;
        z-index: 1;

        span {
          display: none;
        }
      }
    }

    .modal-content {
      overflow: hidden;
    }

    .time-wrap {
      .time-input-box {
        .icon {
          margin-right: 5px;
        }

        .rc-time-picker {
          .rc-time-picker-input {
            border: none;
            color: $default-color;
            font-weight: normal;
            font-size: $font-14;
            line-height: 24px;
          }
        }
      }

      em {
        margin: 0 10px;
        color: $default-color;
        position: relative;

        &::before {
          content: '';
          height: 2px;
          width: 11px;
          background-color: $default-color;
          display: block;
        }
      }
    }
  }

  &.task-details-modal {
    .modal-header {
      .action-btn-wrap {
        margin-right: 30px;

        .dropdown-toggle {
          span {
            margin-right: 0;
          }
        }
      }
    }

    &.modal-right {
      &.fade {
        &.show {
          .modal-dialog {
            right: 0;
            left: auto;
          }
        }
      }

      .modal-dialog {
        height: 100%;
        width: 50%;
        max-width: none;
        height: 100%;
        .modal-content {
          border-radius: 0;
          overflow: inherit;

          .modal-header {
            display: flex;
            align-items: center;
            padding: 30px 40px 20px 40px;

            .modal-title {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: rgba(37, 35, 80, 1);
            }

            .task-description-modal-btn {
              margin-right: 30px;
              box-shadow: none;

              span {
                margin: 0;

                &.icon-more-horizontal {
                  font-size: 4px;
                }
              }
            }
          }

          .modal-body {
            padding: 0;

            .tab-content {
              padding: 0;
              height: calc(100vh - 190px);
            }

            .common-tab-wrap {
              .tab-navbar {
                border-bottom: 1px solid $border-light;

                .nav-item {
                  margin-bottom: -1px;
                }
              }

              .form-footer {
                position: fixed;
                width: 100%;
                bottom: 35px;
                left: 0;
                padding: 20px 40px;
                border-left: 1px solid rgba(0, 0, 0, 0.7);
                border-right: 1px solid rgba(0, 0, 0, 0.7);
                background-color: $white;

                .time-wrap {
                  display: flex;
                  align-items: center;

                  span {
                    margin-right: 10px;
                    color: $icon-color;
                  }

                  .dueTimeDate {
                    position: absolute;
                    height: 23px;
                    left: 76px;
                    top: 60px;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    letter-spacing: 0.01em;
                    color: #252350;
                  }

                  .startDueList {
                    font-size: 15px;
                    letter-spacing: 0.01em;
                    color: #252350;
                  }

                  p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: $font-14;
                    line-height: 24px;
                    color: rgba(66, 63, 121, 0.7);
                    margin-right: 8px;
                  }

                  em {
                    font-style: normal;
                    font-weight: 500;
                    font-size: $font-14;
                    line-height: 24px;
                    color: $default-color;
                  }

                  &.due-time-wrap {
                    .orange-badge {
                      margin-left: 5px;
                      color: $status-incomplete;
                      font-weight: 500;
                      font-size: $font-12;
                      line-height: 24px;
                    }
                  }
                }

                .alert-msg {
                  width: 100%;
                  position: absolute;
                  max-width: inherit;
                  left: 0;
                  height: auto;
                  top: 0;
                  border-radius: 0;
                }

                .completed-msg-wrap-alert,
                .inprogress-msg-wrap-alert p,
                .inprogress-msg-wrap-alert .dueTime {
                  font-family: 'Manrope';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                }

                .inprogress-msg-wrap-alert .dueTime {
                  font-weight: 500;
                }
              }
            }

            .task-details-form {
              position: relative;
              height: calc(100vh - 118px);

              .details-wrap {
                padding: 15px 40px;
                height: calc(100vh - 200px);
                overflow: auto;

                .room-info-wrap {
                  background-color: rgba(216, 226, 240, 0.3);
                  padding: 0;
                  border-radius: 4px;

                  .room-info-title {
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;

                    .room-number-wrap {
                      padding-right: 30px;
                      border-right: 1px solid rgba(0, 0, 0, 0.1);
                      flex-shrink: 0;

                      span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                      }

                      h3 {
                        font-weight: 500;
                        font-size: 26px;
                        line-height: 31px;
                      }
                    }

                    .room-type-info {
                      padding: 0 120px 0 20px;
                      display: flex;
                      align-items: center;
                      position: relative;
                      width: calc(100% - 100px);

                      h4 {
                        margin-right: 30px;
                      }

                      img {
                        margin-right: 10px;
                      }

                      .btnbox {
                        position: absolute;
                        right: 0;

                        .btn {
                          box-shadow: none;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 24px;
                          color: $text-light;
                          padding: 0;
                          margin: 0 !important;

                          span {
                            margin: 0 0 0 5px;

                            &::before {
                              color: $text-light;
                            }

                            &.icon-close {
                              font-size: 10px;
                            }
                          }
                        }
                      }
                    }
                  }

                  .room-details {
                    padding: 20px 20px 10px;
                  }
                }

                .created-task-details {
                  border-top: 1px solid rgba(219, 226, 240, 0.4);
                  padding: 10px 20px;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;

                  .created-task-info {
                    display: flex;
                    align-items: center;
                    margin-right: 50px;

                    &:last-child {
                      margin-right: 0;
                    }

                    label {
                      margin-bottom: 0;
                      margin-right: 15px;
                      font-weight: normal;
                      font-size: $font-14;
                      line-height: 24px;
                      color: $text-secondary-dark;
                    }
                  }
                }
              }

              .details-info-item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 15px;

                &:last-child {
                  margin-bottom: 0;
                }

                .titlebox {
                  width: 175px;
                  padding-right: 20px;
                  flex-shrink: 0;

                  label {
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: $primary;

                    span {
                      margin-right: 10px;
                    }
                  }
                }

                .info {
                  width: calc(100% - 175px);

                  p {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 19px;
                    color: $text-secondary-dark;
                    margin-bottom: 5px;
                  }

                  .bed-type-group {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    span {
                      font-weight: normal;
                      font-size: 16px;
                      line-height: 24px;
                      color: rgba(37, 35, 80, 0.4);
                      position: relative;
                      display: flex;
                      align-items: center;
                      margin-right: 30px;

                      &::after {
                        content: '';
                        position: absolute;
                        right: -15px;
                        width: 4px;
                        height: 4px;
                        border-radius: 50px;
                        background-color: rgba(37, 35, 80, 0.3);
                      }

                      &:last-child {
                        &::after {
                          display: none;
                        }
                      }
                    }
                  }

                  .name {
                    border: 1px solid $border-light;
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 10px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(37, 35, 80, 0.7);
                  }

                  .attachment-wrap {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .imgbox {
                      width: 72px;
                      height: 72px;
                      border-radius: 4px;
                      overflow: hidden;
                      margin-right: 8px;
                      margin-bottom: 8px;
                    }
                  }
                }
              }

              .room-description {
                padding: 20px 0;

                .description-info-item {
                  font-size: 14px;
                  .titlebox {
                    label {
                      color: $text-icon-color;
                      font-weight: 500;
                      font-size: $font-14;
                      line-height: 24px;
                    }
                  }

                  .info {
                    h6 {
                      font-weight: 500;
                      font-size: $font-16;
                      line-height: 24px;
                      color: $primary;
                    }
                  }
                }
              }
            }

            .checklist-form {
              position: relative;
              height: calc(100vh - 200px);
              overflow: auto;

              .progressbar-outer {
                padding: 12px 40px;
                margin-bottom: 10px;

                .progressbar-wrap {
                  width: 100%;
                }

                h6 {
                  flex-shrink: 0;
                }
              }
            }

            .task-checkbox-list {
              &.multicheck-list {
                .title {
                  span {
                    font-size: 1.5px;
                    // &::before{
                    //   content: '\e91c';
                    // }
                  }

                  &.collapsed {
                    span {
                      font-size: 14px;

                      //
                      &::before {
                        content: '\e921';
                        // content: '\e91c';
                      }
                    }
                  }
                }
              }

              .title-wrappe {
                padding: 15px 40px;
              }

              h6 {
                &.title {
                  font-weight: 500;
                  font-size: $font-18;
                  line-height: 21px;
                  cursor: pointer;
                  color: #484585;
                  display: flex;
                  align-items: center;

                  span {
                    margin-right: 20px;
                    font-size: 14px;

                    &.icon-minus-line {
                      font-size: 1.5px;
                    }

                    &::before {
                      color: #484585;
                    }
                  }
                }
              }

              .check-list {
                li {
                  padding: 24px 40px;
                  border-bottom: 1px solid rgba(219, 226, 240, 0.5);
                }

                .form-check {
                  padding-left: 0;
                }
              }
            }

            .multicheck-list {
              .card {
                border: none;

                .card-header {
                  padding: 0;
                  margin-bottom: 0;
                  background-color: transparent;
                  border-bottom: none;
                }

                .card-body {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }

    .comments-wrap {
      padding: 30px 40px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $white;
      @include transition-0-5s;
      opacity: 1;

      &.show {
        right: 0;
        @include transition-0-5s;
        opacity: 1;
      }

      .header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        h3 {
          font-weight: normal;
          font-size: $font-24;
          line-height: 32px;
          color: $default-color;

          span {
            margin-right: 10px;
            font-size: $font-20;
            display: inline-block;

            &::before {
              color: $default-color;
            }
          }
        }

        .btn {
          box-shadow: none;
          margin-left: 20px;
          opacity: 1;

          span {
            margin-right: 0;
            font-size: $font-16;
          }
        }
      }

      .comment-list-form {
        position: relative;
        height: calc(100% - 50px);
        .comment-list {
          .list-item {
            padding-right: 110px;
            position: relative;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }

            .avtar-wraper {
              align-items: flex-start;

              .namebox {
                h6 {
                  font-size: $font-16;
                  font-weight: 500;
                }

                p {
                  color: rgba(21, 19, 57, 0.7);
                }
              }
            }

            .comment-time-wrap {
              position: absolute;
              right: 0;
              display: flex;
              align-items: center;
              top: 0;

              .time {
                font-weight: normal;
                font-size: $font-14;
                line-height: 19px;
                margin-right: 15px;
                color: $text-light;
              }
            }
          }
        }

        .type-comment-box {
          position: absolute;
          bottom: 0;
          width: 100%;

          .textarea-outer {
            position: relative;

            &.active {
              .btnbox {
                display: flex;
              }
            }

            textarea {
              resize: none;
              height: 50px;

              &:focus {
                height: 120px;
                padding-bottom: 50px;
              }
            }

            .btnbox {
              position: absolute;
              bottom: 10px;
              width: calc(100% - 10px);
              right: 10px;
              display: none;
              align-items: center;
              justify-content: flex-end;
            }
          }

          .btnbox {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &.select-assign-modal {
    .modal-dialog {
      max-width: 600px;
    }

    .modal-body {
      padding: 0;

      .form-header {
        padding: 30px 30px 5px;
        border-bottom: 1px solid $border-light;

        .form-group {
          margin-bottom: 15px;

          &.input-with-icon {
            .search-icon {
              font-size: 12px;
              color: $icon-color;
            }
          }
        }
      }

      .form-body {
        ul {
          margin-bottom: 20px;

          &.item-list-wrap {
            li {
              &.item {
                cursor: pointer;

                .avtar-wraper {
                  .imgbox {
                    position: relative;
                    width: 36px;
                    height: 36px;

                    .orange-overlay {
                      position: absolute;
                      left: 0;
                      top: 0;
                      border-radius: 100%;
                      background-color: rgba(255, 132, 68, 0.9);
                      width: 100%;
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                      display: none;

                      .icon {
                        font-size: $font-14;

                        &::before {
                          color: $white;
                        }
                      }
                    }
                  }
                }

                &.active {
                  .imgbox {
                    .orange-overlay {
                      display: flex;
                    }
                  }
                }
              }
            }
          }

          li {
            padding: 10px 30px;
            border-bottom: 1px solid $border-light;

            &:last-child {
              border-bottom: none;
            }
          }

          &.department-list {
            li {
              padding: 20px 30px;
            }
          }
        }
      }

      .form-footer {
        padding: 15px 20px;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
      }
    }
  }

  &.assigned-success-modal {
    .modal-content {
      overflow: hidden;
    }

    .modal-body {
      padding: 0;

      .assigned-success-form {
        .form-header {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: $light-grey-bg;
          padding: 35px 20px;

          .iconbox {
            border: 1px solid rgb(104 104 255);
            width: 70px;
            height: 70px;
            border-radius: 100%;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            span {
              width: 60px;
              height: 60px;
              background-color: $secondary;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;

              &::before {
                color: $white;
              }
            }
          }

          h3 {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: $primary;
            margin-bottom: 5px;
          }

          span {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: rgba(37, 35, 80, 0.7);
          }
        }

        .form-body {
          padding: 35px;
          min-height: 180px;

          .assigned-employee {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .avtar-wraper {
              .imgbox {
                width: 48px;
                height: 48px;
              }
            }

            .btn {
              font-size: $font-14;
              font-weight: normal;

              span {
                margin-left: 10px;
              }
            }
          }
        }

        .form-footer {
          padding: 0 35px 35px;

          .btn {
            margin-left: 0;
            min-width: 95px;
          }
        }
      }
    }
  }

  &.archive-modal {
    .modal-dialog {
      max-width: 320px;
    }

    .modal-title {
      font-size: 18px;
    }

    .archive-task-form {
      .form-body {
        margin-bottom: 30px;

        .radio-btn-group {
          .custom-radiobox {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  &.attach-checklist-modal {
    .modal-dialog {
      max-width: 1190px;
      .modal-content {
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        .modal-body {
          padding: 0;

          .attach-checklist-form {
            .form-header {
              padding: 24px 35px;
              border-bottom: 1px solid #dbe2f0;

              .modal-title {
                font-weight: 700;
                font-size: $font-24;
                line-height: 32px;
                color: $primary;
              }

              .btn {
                box-shadow: none;

                &.close {
                  opacity: 1;

                  span {
                    margin-right: 0;
                    font-size: 18px;
                  }
                }
              }
            }
            .searchbox {
              background: white;
              border-radius: 4px;
              .search-info-wrap {
                top: 58px;
              }
              .form-control::placeholder {
                color: #666584;
              }
              .form-control:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #666584;
              }
              .form-control::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #666584;
              }
              .search-info-wrap {
                top: 58px;
              }
            }

            .form-body {
              .checklist-folder-navbar {
                .nav-item {
                  max-width: 295px;

                  .nav-link {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
            }
          }

          .checklist-preview-container {
            height: calc(100vh - 110px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .common-tab-wrap {
            .tab-navbar {
              padding: 0 35px;
              border-bottom: 1px solid #dbe2f0;
            }

            .tab-content {
              padding: 0;

              .vertical-tabs-wrap {
                display: flex;

                .vertical-nav-wrap {
                  width: 40%;
                  background-color: $body-light-bg;
                  padding: 0;
                  position: relative;
                  flex-direction: column;
                  align-items: flex-start;
                  border-bottom: none;
                  height: calc(100vh - 110px);
                  border-bottom-left-radius: 7px;

                  .vertical-tabs {
                    background-color: $body-light-bg;
                    padding: 0;
                    position: relative;
                    border-bottom: none;
                    height: calc(100vh - 269px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    display: block;

                    .nav-item {
                      width: 100%;
                      position: relative;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      .nav-link {
                        padding: 20px;
                        position: relative;
                        box-shadow: 1px 1px 3px #e9e9f4;
                        background: $white;
                        width: 100%;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                        &.active,
                        .show.active {
                          border: 1px solid $dark-border;
                        }
                      }
                    }
                  }

                  .btn-attach {
                    width: 100%;
                    border-radius: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 72px;
                    font-weight: 700;
                    border-radius: 7px;
                  }
                }

                .vertical-tab-content {
                  width: 50%;
                  background-color: $white;

                  position: relative;

                  .check-list {
                    height: calc(100vh - 220px);
                    position: relative;
                    padding: 15px 0;
                    overflow: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.chat-info-model {
    .modal-header {
      display: block !important;
      padding: 36px 0px 18px 34px !important;
      margin-left: 11px !important;
      border-bottom: none !important;

      .icon-close {
        position: absolute;
        right: 9.5% !important;
        top: 51.67% !important;
        font-size: 16px;

        &::before {
          color: #7571c5;
        }
      }
    }

    .modal-body {
      padding: 0px 16px 25px !important;

      .member-text {
        font-weight: 500 !important;
        font-size: 15px !important;
        line-height: 139% !important;
        letter-spacing: 0.01em !important;
        color: #666584 !important;
      }

      .add-item-list {
        padding-left: 8px !important;
        margin-top: 37px;
      }

      .employee-avtar-item {
        padding: 5px 30px !important;
        border-bottom: none !important;
      }

      .info-member-add {
        color: #6868fe;
        position: absolute;
        margin-top: 6px !important;
        margin-left: 53px;
        cursor: pointer;
      }
    }
  }

  &.communication-modal {
    .modal-dialog {
      max-width: 600px;
    }

    .modal-header {
      border-bottom: 6px solid #f4f6f9;
      padding-bottom: 15px;

      .close {
        display: block;
        font-size: $font-18;
        padding: 5px;
        opacity: 1;
      }

      .title-wrap {
        span {
          &.sub-text {
            font-weight: normal;
            font-size: $font-14;
            line-height: 17px;
            color: rgba(66, 63, 121, 0.7);
          }
        }
      }
    }

    .modal-body {
      padding: 0;

      .create-group-modal-form {
        .add-remove-member {
          .remove-member-icon {
            display: none;
          }

          &:hover {
            span {
              &::before {
                color: gray;
                cursor: pointer;
              }
            }

            .remove-member-icon {
              display: block;
            }
          }
        }

        .addMember-formHeader {
          padding: 0px 30px 0 !important;
        }

        .form-header {
          padding: 30px 30px 0;

          .title-wrap {
            margin-bottom: 20px;

            span {
              &.sub-text {
                font-weight: normal;
                font-size: $font-14;
                line-height: 17px;
                color: rgba(66, 63, 121, 0.7);
              }
            }
          }

          .form-group {
            margin-bottom: 20px;
          }

          .searchbox {
            .form-control {
              background: $white;
              border: 1px solid $input-border;
              border-radius: 4px;
              height: 40px;
              color: $text-primary;
              font-size: 16px;
            }

            span {
              &::before {
                color: $icon-color;
              }
            }
          }
        }

        .form-body {
          .filter-wrap {
            border-bottom: 1px solid rgba(219, 226, 240, 0.5);
            padding: 0 30px 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            span {
              font-weight: normal;
              font-size: $font-14;
              line-height: 17px;
              color: $text-secondary-dark;
              margin-right: 20px;
            }

            .filters-button-group {
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              .btn {
                margin: 2px;
                text-transform: capitalize;
                padding: 4px 12px;
                line-height: 18px;

                &:hover,
                &.active,
                &:active {
                  color: $white;
                  background-color: $btn-primary;
                  box-shadow: none;
                  border: 1px solid $btn-primary;
                }
              }
            }
          }

          .list-title {
            padding: 15px 30px 10px;

            h6 {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: $primary;
              text-transform: uppercase;
            }
          }

          .list {
            &.add-item-list {
              max-height: 400px;
              overflow: auto;
            }

            .employee-avtar-item {
              border: none;
              border-bottom: 1px solid rgba(219, 226, 240, 0.5);
              padding: 10px 30px;
              position: relative;

              &:hover {
                background-color: #f8f8ff;
              }

              &.item-selected {
                &::before {
                  content: '\e90d';
                  color: $secondary;
                  font-size: 14px;
                  position: absolute;
                  right: 15px;
                  font-family: 'icomoon' !important;
                }
              }
            }
          }
        }

        .form-footer {
          padding: 15px 30px;
          box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.06);
        }
      }

      .info {
        position: relative;
        padding-right: 75px;

        border-bottom: 6px solid #f4f6f9;
        padding: 15px 100px 15px 30px;

        h6 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        .content {
          max-height: 48px;
          overflow: hidden;
          margin-bottom: 8px;

          &.view {
            max-height: inherit;
          }
        }

        p {
          margin-bottom: 8px;
        }

        .btn {
          &.btn-primary-link {
            span {
              margin: 0 0 0 15px;
            }
          }
        }

        .badge {
          position: absolute;
          right: 30px;
          top: 8px;
          background-color: $dark-grey-bg;
          font-weight: 500;
          font-size: 12px;
          line-height: 21px;
          color: rgba(21, 19, 57, 0.5);
        }
      }
    }

    &.group-details-modal {
      .employee-avtar-item {
        position: relative;
        .checkBox {
          display: flex;
          align-items: center;
          margin-left: 24px;
        }
        .avtar-wraper {
          width: 100%;
          position: relative;

          .action-btnbox {
            position: absolute;
            right: 0;

            .btn {
              box-shadow: none;
              margin-left: 25px;
              text-transform: capitalize;
              font-weight: normal;

              span {
                margin-right: 0;
              }

              &.leave-btn {
                color: $danger;
              }

              &.remove-btn {
                span {
                  &::before {
                    color: $danger;
                  }
                }
              }

              &.more-btn {
                span {
                  &::before {
                    color: $icon-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.profile-card-modal {
    .modal-content {
      border-radius: 10px;
    }

    .modal-body {
      padding: 0;
      position: relative;

      .btn-close {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 1;
        opacity: 1;
        font-size: 16px;

        span {
          &::before {
            color: $white;
          }
        }
      }

      .profile-imgbox {
        border-radius: 10px 10px 0 0;
        position: relative;

        .overlay {
          background: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: flex-end;
          padding: 20px;

          h3 {
            color: $white;
          }
        }
      }

      .profile-info {
        border-radius: 0 0 10px 10px;

        ul {
          display: flex;
          align-items: center;
          border-bottom: 6px solid #f2f5f9;

          li {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 20px;

            &::before {
              content: '';
              width: 1px;
              height: 23px;
              background-color: rgba(0, 0, 0, 0.1);
              position: absolute;
              right: 0;
            }

            .link {
              color: $primary;
              display: flex;
              align-items: center;

              span {
                margin-right: 10px;

                &::before {
                  color: $secondary;
                }
              }
            }
          }
        }

        .info-item {
          border-bottom: 1px solid $border-light;
          padding: 15px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          label {
            font-weight: normal;
            font-size: $font-14;
            line-height: 24px;
            color: $text-secondary;
          }

          span {
            font-weight: normal;
            font-size: $font-14;
            line-height: 24px;
            color: $primary;
          }
        }
      }
    }
  }

  &.admin-rights-modal {
    .modal-title {
      padding-left: 30px;

      span {
        font-size: 18px;

        &::before {
          color: $select-down-arrow;
        }
      }
    }

    .modal-header {
      border-bottom: none;

      .close {
        background-image: url('../../img/backIcon.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 5px;
        font-size: 0;
        height: 20px;
        position: absolute;
        top: 47px;
        left: 32px;

        span {
          font-size: 0;
        }
      }
    }
  }
}

.comment-list {
  max-height: 425px;
  height: 100%;
  overflow: auto;
}

.comment-modal {
  max-height: 450px;
}

.download-app {
  .modal-dialog {
    max-width: 355px;
    .modal-content {
      border-radius: 24px;
      .modal-header {
        border-bottom: none;
        .icon-close {
          font-size: 16px;
          font-weight: 700;
          color: #7571c5;
        }
      }
      .modal-footer {
        border-top: none;
      }
    }
  }
}

.app-download-dropdown-header {
  font-weight: 400;
  font-size: 12px;
  color: #7b79a1;
}

.task-details-form {
  .task-category {
    font-weight: 700;
  }
}

.attach-checklist-form {
  .input-with-icon.form-group {
    border: 1px solid #d8dadd;
  }
}

.confirm-modal {
  .modal-dialog {
    max-width: 430px;
    .modal-header {
      font-size: 20px;
      font-weight: 700;
      color: #252350;
    }
    .modal-body {
      padding-top: 16px;
      .form-body {
        font-size: 18px;
        font-weight: 400;
        color: #252350;
        letter-spacing: 0.46px;
      }
    }
  }
}

.details-wrap {
}
