.main-layout-outer {
  .layout-left {
    width: 450px;
    height: 100vh;
    position: relative;
    padding: 53px 0 0 43px;
    img {
      width: 145px;
      height: 30px;
    }
    .sidebarFeaturedText {
      font-size: 38px;
      margin: 100px 0 0 0;
      line-height: 54.57px;
      color: #ffffff;
    }
  }
}
.or-divider {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  span {
    background-color: $body-light-bg;
    padding: 3px;
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.login-layout {
  display: flex;
  align-items: center;
  position: relative;
  height: 100vh;
  width: calc(100% - 450px);
  a.disabled {
    pointer-events: none;
    cursor: default;
    color: white !important;
  }
  .error-border {
    border: 1px solid #e73535;
  }
  .changeLoginType {
    color: #6868fe !important;
    cursor: pointer;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
  .email-confirmation-form {
    .instruction {
      color: #7b79a1 !important;
    }
    .resendEmail {
      display: flex;
      justify-content: center;
    }
  }
  .forgot-password-heading {
    font-weight: 500;
    color: #000000;
  }
  .react-tel-input .form-control {
    width: 100% !important;
    height: 40px !important;
  }
  .copyright-reserved {
    position: relative;
    top: 90px;
    font-size: 10px;
    color: #a09fbc;
  }
  .top20 {
    position: relative;
    top: 20px !important;
  }
  .login-form {
    .neTop16 {
      margin-top: -16px !important;
    }

    .login-form-heading {
      font-weight: 200;
    }
    .font-weight-500 {
      font-weight: 500;
    }
    .privacy-policy-text {
      font-weight: 400;
      font-size: 14px !important;
      line-height: 20px;
      color: #7b79a1;
    }
    .privacy-link,
    .changeLoginType {
      color: #6868fe !important;
      cursor: pointer;
    }
    .changeLoginType {
      font-family: 'Manrope';
      font-size: 16px;
      font-weight: 700;
    }
    .privacy-link {
      font-size: 14px;
      .privacy-policy {
        margin-left: 4px;
      }
    }

    .label-password,
    .label-email {
      color: #666584 !important;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    .forgot-password {
      position: absolute;
      right: 16px;
      color: #6868fe !important;
      z-index: 1;
      top: -16px;
    }
  }
  .img-wrap {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding-left: 6%;
    &::after {
      content: '';
      background-position: 0 0;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      width: 55%;
    }
    .banner-img {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        max-width: 662;
        height: auto;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 40px;

    form {
      max-width: 360px;
      width: 100%;
      @include media(down, $breakpoint-xmd) {
        max-width: 330px;
      }
      .logo {
        margin: 0 auto 70px;
        width: auto;
        @include media(down, $breakpoint-lg) {
          margin: 0 auto 40px;
        }
      }
      .form-group {
        label {
          // text-transform: uppercase;
          color: $text-primary;
          font-weight: 400;
        }
      }
      .alert-msg {
        margin-bottom: $margin-bottom-30;
        @include media(down, $breakpoint-lg) {
          margin-bottom: $margin-bottom-20;
        }
      }
      .btn {
        text-transform: uppercase;
      }
      .checkbox-wrap {
        display: flex;
        justify-content: space-between;
      }
      .link {
        color: $light-link-color;
        font-size: 13px;
        line-height: 24px;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
          color: $text-primary;
        }
      }
      p {
        &.copy-right {
          color: $grey;
          font-size: 11px;
          line-height: 24px;
          margin: 160px 0 0;
          @include media(down, $breakpoint-lg) {
            margin: 70px 0 0;
          }
          @include media(down, $breakpoint-md) {
            margin: 30px 0 0;
          }
        }
      }
    }
  }
}
