/* =========== IMAGE PATH VARIABLE ============= */
$assetPath: '../../images';
/* Usage of bacground image */
//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;

// Color system
$white: #ffffff;
$dark-black: #000000;
$black: #222222;
$grey: #7e858e;
$icon-color: #8684b7;

$primary: #252350;
$primary-main: #6868fe;
$primary-50: #ebeaff;
$primary-100: #cccbff;
$primary-200: #a9a9ff;
$primary-500: #4e48fa;

$secondary: #6868fe;
$danger: #ef0d36;
$default-color: #151339;

$table-box-shadow: #c9c9c9;
$table-head-text: $default-color;

// Text
$text-primary: $primary;
//$text-secondary: #666585;
$text-secondary: #212121;
$text-grey-light: #d1d1da;
$text-grey: #a6a6c5;
$text-grey-dark: #7e858e;
$text-white: #ffffff;
$text-secondary-dark: #423f79;
$text-secondary-light: #484585;
$text-light: #8e8cac;
$text-light-primary: rgba(0, 0, 0, 0.87);
$text-light-secondary: rgba(0, 0, 0, 0.6);
$text-light-disabled: rgba(0, 0, 0, 0.38);
$text-extra-light: #a7bcd9;
$text-icon-color: $icon-color;
$text-archive: #eb5757;
$checklist-nav-title: rgba(0, 0, 0, 0.87);
$upload-file-text: #847265;
$text-dark-brown: #4d4437;

// $text-primary:#151339;
// $text-secondary:#423F79;
// $text-light:#8E8CAC;
// $text-grey-dark:#7E858E;

// Link
$link-color: #6868fe;
$light-link-color: #6d6b8e;
$light-action-active: rgba(0, 0, 0, 0.54);
$light-text-disabled: rgba(0, 0, 0, 0.38);

// Font
$font-family-base: 'Manrope', sans-serif;
$font-size-base: 16px; // Assumes the browser default, typically `16px`

// Button
$btn-primary-dark: #252350;
$btn-primary: #6868fe;
$btn-secondary: #2dca73;
$btn-grey: #d7d7e0;
$btn-dark: #26292d;

// Border
$input-border: $text-extra-light;
$search-input-border: #7d78c9;
$border-light: #dbe2f0;
$border-extra-light: #f4f6f9;
$select-arrow: #636363;
$select-down-arrow: #6e6c92;
$dark-border: #484585;

// BG Color
$primary-bg: #151339;
$body-light-bg: #f2f5f9;
$dark-grey-bg: #e9e9f4;
$light-grey-bg: #f0f4fc;
$extra-grey-bg: #f6f8fa;
$message-bg: #363544;
$more-bg: #b5b7c4;
$blue-light-bg: #534f9e;
$hotel-admin-bg: #fafafa;
$selectbox-selected-item-bg: #f0f4fc;
$list-hover-bg: rgba(156, 39, 176, 0.08);
$grey-light-bg: #d8d8d8;
$date-bg: #eaeef6;
$avtar-bg: #def3ff;
$warning-light-bg: #fff1db;
$task-table-header-bg: #e5ecf6;
$pink-bg: #ffe9ee;

// status color
$status-light-grey: #c4c4c4;
$status-active: #2dca73;
$status-inactive: #d7d7e0;
$status-unassigned: #3b8ef0;
$status-not-started: #f58500;
$status-in-progress: #f58500;
$status-paused: #64636d;
$status-incomplete: #d13c1b;
$status-complete: #04b86c;
$status-open: #3b8ef0;
$status-on-hold: #64636d;

/* Badge bg */
$green-bg: #04b86c;
$green-light-bg: #effff4;
$orange-light-bg: #ffe3e3;
$brown-light-bg: #ffe3e3;

/* Badge Text */
$badge-title-text: #615f98;
$badge-green-text: #04a3ad;
$badge-orange-text: #e54040;
$badge-brown-text: #e54040;

// Dot color
$dot-light-grey: $status-light-grey;
$dot-active: $status-active;
$dot-inactive: $status-inactive;
$dot-unassigned: $status-unassigned;
$dot-not-started: $status-not-started;
$dot-in-progress: $status-in-progress;
$dot-paused: $status-paused;
$dot-incomplete: $status-incomplete;
$dot-complete: $status-complete;
$dot-open: $status-open;
$dot-on-hold: $status-on-hold;

// Gradiant
$green-gradiant: linear-gradient(90deg, #58c463 0%, #37b185 100%);
$orange-gradiant: linear-gradient(90deg, #f49237 0%, #ed6547 100%);
$red-gradiant: linear-gradient(90deg, #ad3704 0%, #9b260c 100%);
$blue-gradiant: linear-gradient(90deg, #3b8ef0 0%, #1e70d1 100%);
$gray-gradiant: linear-gradient(90deg, #818385 0%, #abaeb1 100%);

// Media BreakPoint
$breakpoint-xxl: 1680.97px;
$breakpoint-xl: 1540.91px;
$breakpoint-xlg: 1440.91px;
$breakpoint-lg: 1280.91px;
$breakpoint-xxmd: 1200.91px;
$breakpoint-xmd: 1024px;
$breakpoint-md: 992px;
$breakpoint-sm: 767px;

// fonts size
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-28: 28px;
$font-32: 32px;
$font-34: 34px;

// Margin
$margin-bottom-50: 50px;
$margin-bottom-45: 45px;
$margin-bottom-40: 40px;
$margin-bottom-35: 35px;
$margin-bottom-30: 30px;
$margin-bottom-25: 25px;
$margin-bottom-20: 20px;
$margin-bottom-15: 15px;
$margin-bottom-14: 14px;
$margin-bottom-10: 10px;
$margin-bottom-5: 5px;

$margin-top-50: 50px;
$margin-top-45: 45px;
$margin-top-40: 40px;
$margin-top-35: 35px;
$margin-top-30: 30px;
$margin-top-25: 25px;
$margin-top-20: 20px;
$margin-top-15: 15px;
$margin-top-14: 14px;
$margin-top-10: 10px;
$margin-top-5: 5px;

$margin-right-5: 5px;
$margin-right-10: 10px;

// Padding
$padding-bottom-50: 50px;
$padding-bottom-45: 45px;
$padding-bottom-40: 40px;
$padding-bottom-35: 35px;
$padding-bottom-30: 30px;
$padding-bottom-25: 25px;
$padding-bottom-20: 20px;
$padding-bottom-15: 15px;
$padding-bottom-14: 14px;
$padding-bottom-10: 10px;
$padding-bottom-5: 5px;

$padding-top-50: 50px;
$padding-top-45: 45px;
$padding-top-40: 40px;
$padding-top-35: 35px;
$padding-top-30: 30px;
$padding-top-25: 25px;
$padding-top-20: 20px;
$padding-top-15: 15px;
$padding-top-14: 14px;
$padding-top-10: 10px;
$padding-top-5: 5px;

$v2-orange: #6868fe;
$v2-red: #e73535;
