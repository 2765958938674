.task-sidebar {
  .task-status-wrap {
    h6 {
      font-weight: 500;
      font-size: $font-14;
      line-height: 24px;
      color: $text-light;
      text-transform: uppercase;
      margin-bottom: $margin-bottom-15;
    }

    .custom-checkbox {
      margin-bottom: 5px;

      .form-check-label {
        padding-left: 30px;
        line-height: 24px;
        color: $primary-bg;
      }
    }
  }
}

.task-home-wrap {
  padding: 0;

  .common-tab-wrap {
    .tab-content {
      padding: 20px 36px 0 36px;
      .info-wrap-title {
      }
    }
  }
}

.modal {
  &.add-task-modal {
    &.modal-right {
      .modal-dialog {
        height: 100%;
        max-width: 505px;

        .modal-content {
          border-radius: 0;
        }
      }
    }

    .modal-body {
      padding: 0px 15px 36px 36px;
      height: calc(100% - 54px);

      .task-description-form {
        height: 114%;
        font-size: 14px;

        .title-textarea-wrap {
          .form-control {
          }
        }

        .info-wrap {
          height: 100%;
          overflow: scroll;
          max-height: calc(100% - 168px);
          padding-right: 10px;
          padding-left: 2px;

          .label-wrap {
            label {
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
              display: block;
              color: $icon-color;
              margin-bottom: 0;
            }

            &span {
              font-weight: normal;
              font-size: $font-12;
              line-height: 12px;
              color: $text-light;
            }
          }

          .content {
            position: relative;
            // width: calc(100% - 145px);

            .icon-close::before {
              color: #a56767;
              font-weight: bold;
            }

            .icon-close {
              position: absolute;
              right: 10px;
              font-size: 8px;
              top: 0;
              background-color: #ffe3e3;
              border-radius: 50px;
              border: 1px solid #ddd;
              padding: 2px;
              width: 20px;
              height: 20px;
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              -webkit-justify-content: center;
              justify-content: center;
              z-index: 0;
              cursor: pointer;
            }

            .form-group {
              margin-bottom: 0;
            }

            .date-time-wrap {
              display: flex;
              align-items: center;
              margin-bottom: 20px;

              .calendar-input-box {
                width: auto;
                margin-right: 25px;
                display: flex;
                align-items: center;
              }

              .btn {
                padding: 0;
                font-size: 12px;
                display: flex;
                line-height: 24px;
                text-transform: capitalize;

                span {
                  margin-right: 6px;
                  font-size: 10px;
                  font-weight: 600;
                }
              }
            }

            .time-wrap {
              margin-bottom: 20px;
            }

            .custom-checkbox {
              margin-bottom: 10px;

              &.checkbox-with-icon {
                .form-check-label {
                  color: $default-color;
                  font-weight: normal;
                  font-size: $font-14;
                  line-height: 24px;
                }
              }
            }

            .employee-list-item {
              display: flex;

              .imgbox {
                width: 28px;
                height: 28px;
                border-radius: 50px;
                margin-right: 10px;
                flex-shrink: 0;
                overflow: hidden;
              }

              .namebox {
                width: calc(100% - 28px);
                position: relative;
                display: flex;
                flex-direction: column;
                position: relative;

                h6 {
                  font-weight: normal;
                  font-size: $font-14;
                  line-height: 24px;
                  color: $default-color;
                }

                span {
                  font-weight: normal;
                  font-size: $font-12;
                  line-height: 12px;
                  color: rgba(66, 63, 121, 0.5);
                }

                .assign-user-close {
                  position: absolute;
                  top: -13px;
                  background-color: #ffe3e3;
                  border-radius: 50px;
                  border: 1px solid #ddd;
                  padding: 2px;
                  width: 20px;
                  height: 20px;
                  display: -webkit-flex;
                  display: flex;
                  -webkit-align-items: center;
                  align-items: center;
                  -webkit-justify-content: center;
                  justify-content: center;
                }

                .assign-user-close:before {
                  content: 'x';
                  color: #a56767;
                  font-size: 12px;
                }
              }

              .user-status-box {
                padding: 4px 10px;
                border-radius: 15px;
                text-transform: capitalize;
                font-size: 12px;
                font-weight: 400;
                color: #252350;

                &.available {
                  background: #D4F4E9;
                }

                &.online {
                  background: #DCF7FF;
                }

                &.busy {
                  background: #FFE9EE;
                }

                &.offline {
                  background: #E8E8E8;
                }
              }
              &.selected-employee {
                line-height: 24px;

                .namebox {
                  .cancel-btn {
                    position: absolute;
                    right: 0;
                    box-shadow: none;

                    span {
                      font-size: 8px;
                      margin-right: 0;
                    }
                  }
                }
              }
            }

            .recurring-task-info {
              display: block;
              background: #f2f5f9;
              border-radius: 6px;
            }

            .task-info {
              span {
                font-weight: normal;
                font-size: $font-14;
                line-height: 24px;
                color: rgba(21, 19, 57, 0.7);
              }

              .calender-wrap {
                margin-left: 5px;
              }
            }

            .week-days {
              margin-top: 10px;
            }
          }
        }

        .form-footer {
          padding: 15px 30px 25px;
          background: #ffffff;
          box-shadow: 0px -1px 0px #e9e9ed;
          position: absolute;
          width: 100%;
          bottom: 27px;
          left: 0;
          // &.show{
          //   .attachment-list{

          //   }
          // }
          .attachment-list {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            overflow: auto;
            padding-bottom: 5px;
            position: absolute;
            bottom: -100%;
            opacity: 0;
            visibility: hidden;
            @include transition-0-3s;

            &.show {
              bottom: 0%;
              visibility: visible;
              opacity: 1;
              position: relative;
              @include transition-0-3s;
            }

            .attachment-item {
              display: flex;
              position: relative;
              margin-top: 4px;
              margin-right: 30px;
              flex-shrink: 0;
              border: 1px solid $border-light;
              padding: 5px;
              border-radius: 4px;
              width: 65px;
              height: 65px;
              justify-content: center;

              .icon-close::before {
                color: #a56767;
                font-weight: bold;
              }

              .icon-close {
                position: absolute;
                right: -5px;
                font-size: 8px;
                top: -5px;
                background-color: #ffe3e3;
                border-radius: 50px;
                border: 1px solid #ddd;
                padding: 2px;
                width: 20px;
                height: 20px;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
              }

              .icon {
                margin-right: 10px;
                flex-shrink: 0;

                &::before {
                  color: $danger;
                }
              }

              .item-name {
                h6 {
                  color: $default-color;
                  font-weight: normal;
                  font-size: $font-14;
                  line-height: 16px;
                  width: 120px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .item-size {
                  color: $text-secondary-dark;
                  font-weight: normal;
                  font-size: $font-13;
                  line-height: 24px;
                }
              }
            }
          }

          .checklist-attachment-list {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            width: 100%;
            overflow: auto;
            padding-bottom: 5px;
            position: absolute;
            bottom: -100%;
            opacity: 0;
            visibility: hidden;
            @include transition-0-3s;
            @include transition-0-3s;

            &.show {
              bottom: 0%;
              visibility: visible;
              opacity: 1;
              position: relative;
              @include transition-0-3s;
            }

            .attachment-item {
              display: flex;
              margin-right: 15px;
              flex-shrink: 0;
              border: 1px solid $border-light;
              padding: 10px;
              border-radius: 4px;
              align-items: center;

              .btn {
                border: none;
                box-shadow: none;
                margin: 0 0 0 10px;

                &:hover {
                  border: none;
                }

                .icon {
                  margin: 0px;
                  flex-shrink: 0;
                  width: 20px;
                  height: 20px;
                  border-radius: 50px;
                  background-color: $brown-light-bg;
                  font-size: 3.5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &::before {
                    color: #a56767;
                  }
                }
              }

              .item-name {
                h6 {
                  color: $default-color;
                  font-weight: 600;
                  font-size: $font-14;
                  line-height: 16px;
                  width: 120px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .item-size {
                  color: $text-secondary-dark;
                  font-weight: normal;
                  font-size: $font-13;
                  line-height: 24px;
                }
              }
            }
          }

          .attachment-btn-wrap {
            .attachment-dropdown {
              .dropdown-menu {
                animation: none;
                left: auto !important;
                right: auto !important;
                top: -10px !important;

                .title {
                  font-weight: bold;
                  font-size: 11px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: rgba(66, 63, 121, 0.5);
                  padding: 12px 15px;
                  margin-bottom: 0;
                }

                .dropdown-item {
                  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }

          .attachment-count-wrap {
            position: relative;
            display: flex;
            align-items: center;

            .btn {
              &.badge {
                border-radius: 25px;
                min-width: 36px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-height: 26px;
                margin-left: 5px;
                padding: 2px 8px;
                box-shadow: none;

                &.count {
                  background-color: $secondary;
                  color: $white;
                  cursor: pointer;
                  box-shadow: none;

                  span {
                    color: $white;
                    font-size: 6px;
                    margin-left: 5px;
                    margin-right: 0;
                    transform: rotateZ(-180deg);

                    &::before {
                      color: $white;
                    }
                  }
                }
              }

              &.close-btn {
                background-color: $border-light;
                color: $text-secondary-dark;
                cursor: pointer;
                border-radius: 25px;
                min-width: 36px;
                display: inline-flex;
                justify-content: center;
                cursor: pointer;
                align-items: center;
                min-height: 26px;
                margin-left: 5px;
                box-shadow: none;
                padding: 2px;

                span {
                  margin-right: 0;
                  display: block;
                  font-size: 10px;
                }
              }
            }
          }
        }

        .select-employee-dropdown {
          position: relative;
        }

        .select-box__menu {
          border-left: 1px solid #E0E0E0;
        }
      }
    }
  }
}

.date-time-wrap {
  display: flex;

  .calendar-input-box {
    .icon {
      &::before {
        color: $icon-color;
      }

      &.icon-down-angle {
        font-size: 8px;
      }

      &.icon-up-angle {
        font-size: 8px;
      }
    }
  }
}

.calendar-input-box {
  .DayPickerInput {
    margin: 0 5px 0 10px;

    input {
      width: 100%;
      text-align: left;
      border: none;
      font-weight: normal;
      font-size: $font-14;
      line-height: 24px;
      color: $table-head-text;
      background: url('../../img/down-angle.svg');
      background-repeat: no-repeat;
      background-position: 100% center;
      background-size: 12px;
      padding-right: 15px;
    }
  }

  .DayPickerInput-Overlay {
    padding: 15px;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
    left: -245px;
    top: -340px;

    .DayPicker-Caption {
      margin-bottom: 0;
    }

    .DayPicker-wrapper {
      padding-bottom: 0;
    }
  }
}

.select-employee-dropdown {
  .dropdown-toggle {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $default-color;
    text-transform: capitalize;

    span {
      &.icon-user-icon {
        font-size: 13px;
      }

      &.icon-down-angle {
        font-size: 7.5px;
        margin: 0 0 0 10px;
        width: 100px;
      }

      &.icon-up-angle {
        font-size: 20px !important;
        margin: 0 0 0 10px;
        width: 100px;
      }
    }
  }

  .dropdown-menu {
    width: 425px;
    max-height: 300px;
    top: auto !important;
    bottom: 100% !important;
    right: auto !important;
    left: 0 !important;
    overflow: auto;
    padding: 16px;

    .input-with-icon {
      width: 92%;
    }
  }

  .select-employee-from {
    max-height: 300px;
    overflow: auto;
    padding: 12px;

    .input-with-icon {
      width: 350px;

      .form-control {
        background: $white;
        border: 1px solid $input-border !important;
        border-radius: 4px;
        height: 40px;
        color: $text-primary;
        font-size: 16px;
        padding: 8px 10px 8px 30px !important;
        height: 40px !important;
      }

      .input-left-icon {
        position: absolute;
        left: 10px;
        font-size: $font-14;
        top: 13px;

        &::before {
          color: rgba(37, 35, 80, 0.4);
        }
      }
    }

    .dropdowm-item {
      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        background: #f2f5f9;
      }
    }
  }
}

.multi-rooms-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .span-group {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

    .room-number {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 35px;
    }
  }

  .form-control {
    display: inline-flex;
    width: 95px;
    margin-bottom: 0 !important;
  }
}

.file-upload-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);

  .upload-file-wrap {
    margin: 30px;
    position: relative;
    height: 100%;

    .file-upload-label {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 5px;
      border: 3px dashed #a7bcd9;
      border-radius: 5px;
      width: 100%;
      height: calc(100vh - 90px);
      left: 30px;
      top: 30px;

      .folder-icon {
        margin-bottom: $margin-bottom-30;
      }

      h6 {
        font-weight: normal;
        font-size: 16px;
        color: $default-color;
      }

      em {
        color: $text-extra-light;
        font-weight: normal;
        font-size: 11px;
        line-height: 24px;
        margin: 5px 0;
        text-transform: uppercase;
      }
    }

    // input[id="file-upload-input"] {
    //   display: none;
    // }
  }

  .upload-container-close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    box-shadow: none;

    span {
      margin-right: 0;
    }
  }
}

.checklist-title-wrap {
  width: 100%;
  position: relative;

  .info-wrap {
    position: relative;
    padding: 15px 20px 15px 0px !important;
    box-shadow: 1px 1px 3px #e9e9f4;
    border-radius: 0px;
    background: $white;
    width: 100%;

    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $checklist-nav-title;
      margin-bottom: 5px;
    }

    .tag-group {
      .tag {
        color: rgba(37, 35, 80, 0.6);
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        display: flex;
        align-items: center;
        margin-right: 15px;

        span {
          font-size: 12px;
          margin-right: 5px;

          &::before {
            color: $secondary;
          }
        }

        em {
          color: $default-color;
          margin-right: 3px;
        }
      }
    }
  }

  .custom-checkbox {
    position: absolute;
    top: 15px;
    left: 25px;
  }
}

.checkbox-item-card {
  padding: 24px 40px;
  border-bottom: 1px solid rgba(219, 226, 240, 0.5);

  h6 {
    font-weight: normal;
    color: $default-color;
    font-size: $font-16;
    line-height: 24px;
  }

  p {
    font-weight: normal;
    font-size: $font-14;
    line-height: 20px;
    color: rgba(21, 19, 57, 0.6);
  }

  .uploaded-item-view {
    margin-bottom: 15px;
  }
}

.uploaded-item-view-group {
  display: flex;
  align-items: center;
}

.uploaded-item-view {
  display: flex;
  align-items: center;
  color: $upload-file-text;
  margin-right: 15px;

  span {
    margin-right: 10px;

    &::before {
      color: $upload-file-text;
    }
  }
}

.list-tag {
  padding: 5px 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.tag-select {
  .select-box__menu {
    top: auto !important;
    bottom: 100% !important;
  }
}

thead.task-list-header {
  background: #e5ecf6;

  th {
    font-weight: 700;
  }
}

.header-left-border {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.header-right-border {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.page-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 32px;
}
