@import '~video-react/styles/scss/video-react';

.video-react .video-react-big-play-button {
  margin-top: 5rem;
}

.checklist-content-wrap {
  padding: 0 50px 50px 50px;
  justify-content: center;
  display: flex;

  .checklist-content {
    max-width: 840px;
    width: 100%;

    .content-title-wrap {
      background-color: transparent;
      padding: 15px 0;
      min-height: auto;

      h3 {
        color: $text-secondary-light;
      }
    }

    .multicheck-list {
      .title-wrappe {
        padding: 25px 40px;
      }
    }
  }
}

.checklist-container {
  height: calc(100vh - 69px); /* viewport - height of the header */
  overflow: scroll;
  padding-bottom: 32px !important;
}

.checklist-list {
  padding: 0 24px;
  border-bottom: 1px solid #dbe2f0;
  .checklist-tabs {
    height: 48px;
    .nav-item {
      // margin-left: 1.5rem;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      border-radius: 0;
      border: none;
      color: #666584;
      text-align: center;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: flex-end;
      // padding-bottom: 12px;
      padding: 14px 24px;
      &.active {
        color: #6868fe;
        background-color: transparent;
        border-bottom: 2px solid #6868fe;
        padding: 12px 24px;
      }
    }
  }

  .checklist-icon-container {
    padding: 6px 8px 8px 8px;
    border-radius: 4px;
    margin-right: 1rem;
  }

  .checklist-avatar {
    width: 28px;
    height: 28px;
    font-size: 0.8rem;
    margin-right: 1rem;
  }

  .actionbox {
    float: right;
  }

  .MuiTableContainer-root {
    .MuiTable-root {
      .MuiTableBody-root {
        .Mui-selected {
          background-color: rgba(156, 39, 176, 0.08);

          .checklist-name {
            color: #252350;
            font-weight: 700;
            text-decoration: underline;
          }
        }
      }

      .MuiTableRow-hover:hover {
        background-color: rgba(156, 39, 176, 0.08);

        .checklist-name {
          color: #252350;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }
}

.checklist-builder {
  .form-control {
    border-color: #c1c1c1;
  }

  .checklist-name {
    .MuiInputBase-input {
      padding: 10.5px 14px;
    }
  }

  .checklist-icon-container {
    padding: 18px 0px 18px 2px;
    border-radius: 4px;
    width: 64px;
    height: 64px;
  }

  .icon-container-overlay {
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    left: 46px;
    bottom: -12px;
  }

  .icon-container-overlay:hover {
    opacity: 1;
  }

  .checklist-item-container {
    padding-left: 0.8rem;
    padding-top: 1rem;
    margin-left: 0.45rem;
    //border-left: 3px solid #6868fe;
  }

  .checklist-item {
    background: white;
    padding: 16px 16px 4px 16px;
    border-radius: 8px;
    border: 1px solid #c5c5c5;

    .checklist-item-row {
      display: flex;
      align-items: center;
    }

    &.first-row {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .checklist-item-row {
        display: flex;
        align-items: center;
      }
    }

    &.second-row {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding: 12px 12px 3px 76px;

      .checklist-item-row {
        display: flex;
        align-items: start;
      }
    }

    .attachments {
      .close-icon {
        visibility: hidden;
      }

      &:hover {
        .close-icon {
          visibility: visible;
        }
      }
    }
  }

  .checklist-heading {
    display: flex;
    align-items: center;
  }

  .checklist-heading-item {
    .MuiInputBase-root::before {
      border-bottom: 2px solid #6868fe;
    }
  }

  .btn:disabled {
    color: #d7d7e0;
  }
}

.checklist-icon-picker {
  .MuiPaper-elevation {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 4px !important;
    padding: 16px 12px 20px 12px;
  }

  .icon-container {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid transparent;

    &:hover {
      border-color: #757575;
    }

    svg {
      font-size: 24px;
    }
  }

  .color-container {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 20px;
    border: 1px solid transparent;

    &:hover {
      border-color: #757575;
    }

    .color-item {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .btn-sm {
    padding: 4px 12px;
    font-size: 14px;
  }

  .text-primary {
    color: #6868fe !important;
  }
}

.checklist-attachments {
  .MuiPaper-elevation {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 16px;
  }
}

.folderlist {
  cursor: pointer;
}

.attachment-preview-dialog {
  .MuiDialog-paperFullScreen {
    background: #3d3d3d;
  }
}

.MuiCheckbox-colorSuccess.Mui-checked {
  color: #4caf50 !important;
}

.item-container {
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
  margin-bottom: 20px;
}

.image-container {
  // width: 63px;
  // height: 80px;
  border-radius: 6px;
  margin-right: 10px;
}

.action-dropdown {
  display: none;
}

.image-container:hover .action-dropdown {
  display: block;
}

.attached-image {
  width: 80px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #f0f4fe;
  object-fit: cover;
}

.draft-image {
  width: 41px;
  height: 52px;
  border-radius: 6px;
  border: 1px solid #f0f4fe;
  object-fit: cover;
}

.attachment-remove {
  position: absolute;
  margin-left: 22px;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  opacity: 0.7;
}

.note-header {
  font-size: 14px;

  .note-date {
    color: rgba(0, 0, 0, 0.6);
  }
}

.note-body {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20.02px;
}

.checklist-note-text-area {
  background: rgba(0, 0, 0, 0.04) !important;
}

.item-error-border {
  border: 1px solid #ef5350;
}

.header-item-container {
  margin-bottom: 14px;
  font-size: 20px;
  font-weight: 700;
  color: #666584;
}

.checklist-attachment-preview {
  .modal-close {
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 60px;
    top: 12px;
    cursor: pointer;

    .close-container {
      background: rgba(0, 0, 0, 0.75);
      padding: 7px 8px 7px 8px;
      cursor: pointer;
      z-index: 1000;
    }
  }

  .modal-close-alt {
    right: 16px;
    top: 12px;
  }

  .left-arrow {
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    left: 16px;

    .left-arrow-container {
      background-color: #454545;
      padding: 12px 13px 12px 11px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1000;
    }
  }

  .disabled-arrow {
    color: #7e7e7e;
  }

  .checklist-document-container {
    text-align: center;
    height: 100vh;
  }

  .checklist-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
  }

  .checklist-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .checklist-image-preview {
      box-shadow: 0px 4px 63px rgba(0, 0, 0, 0.35);
      max-height: 100vh;
    }
  }

  .right-arrow {
    color: white;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    right: 16px;
    top: 7%;

    .right-arrow-container {
      background-color: #454545;
      padding: 12px 11px 12px 13px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1000;
    }
  }
}

.checklist-log-report {
  .MuiFormControlLabel-label.Mui-disabled {
    color: inherit !important;
  }

  .is-active {
    .nav-item-container {
      border-left-color: #6868fe;
    }

    .nav-item {
      color: #6868fe;
    }
  }

  .pdf-page-header {
    display: none;
  }

  .checklist-log-item {
    .checklist-log-not-last-item {
      margin-bottom: 1rem;
    }
  }
}

@media print {
  .checklist-log-report {
    .main-header {
      background-color: #f5f5f5;
      border-bottom: none !important;
      padding-left: 2rem !important;
      height: 99px;

      .download-pdf-btn {
        display: none;
      }
    }

    .left-container {
      display: none;
    }

    .right-container {
      max-width: 100%;
      flex-basis: 100%;
      margin-left: 0px;
      padding: 0rem 1rem;
    }

    #overview {
      .MuiAccordionSummary-root {
        display: none;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      display: none;
    }

    .MuiAccordionSummary-root {
      border-radius: 0;
      margin: 0px 16px;
      background: #ebeaff;

      .header-title {
        font-weight: 600;
        color: #3100bc;
      }
    }

    .MuiAccordionDetails-root {
      background-color: #fff;
    }

    .image-note-container {
      border-radius: 0;
      margin-bottom: 1rem;
    }

    .checklist-log-not-last-item {
      border-bottom: 1px solid #dee2e6 !important;
      border-radius: 0;
      margin-bottom: 0 !important;
    }

    .checklist-log-item {
      page-break-inside: avoid;
      page-break-before: auto;
    }

    .checklist-log-header {
      page-break-inside: avoid;
      page-break-before: auto;
    }

    .pdf-page-header {
      display: block;
      page-break-before: always;
      margin: 0rem -1rem;
    }

    .appendix-row {
      page-break-inside: avoid;
      page-break-before: auto;
    }

    .appendix-count {
      display: none;
    }

    .overview-row {
      padding: 1.5rem 0 !important;
    }

    .photo-count {
      display: block !important;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;
      font-size: 10px;
    }
  }
}

.create-checklist-modal {
  .modal-dialog {
    max-width: 668px;
  }

  .email-link {
    color: #6868fe;
    font-weight: 500;
    font-size: 12px;
  }
}

.step-type-modal {
  .modal-dialog {
    max-width: 998px;
  }
  .modal-content {
    background: #f5f6fb;
  }
}

#create-checklist-modal {
  .modal-content {
    border-radius: 16px !important;
  }
}
