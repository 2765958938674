.customDatePicker .rdp {
  --rdp-cell-size: 41px;
  --rdp-accent-color: #ebeaff;
  --rdp-background-color: #ebeaff;
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: none;
  --rdp-outline-selected: none;
  margin: 0;
  padding: 10px;
}

.customDatePicker .rdp-day_selected:not([disabled]) {
  color: white !important;
  background-color: #6868fe !important;
}

.customDatePicker .rdp-button:not(.rdp-day_selected):hover {
  color: #6868fe !important;
}
.customDatePicker .rdp-day_selected:hover {
  color: #fff !important;
}
.customDatePicker
  .rdp:not([dir='rtl'])
  .rdp-day_range_start:not(.rdp-day_range_end) {
  background-color: #6868fe;
  color: white;
}
.customDatePicker
  .rdp:not([dir='rtl'])
  .rdp-button.rdp-day_range_start:not(.rdp-day_range_end):hover {
  color: white !important;
}
.customDatePicker
  .rdp:not([dir='rtl'])
  .rdp-day_range_end:not(.rdp-day_range_start) {
  background-color: #6868fe;
  color: white;
}
.customDatePicker
  .rdp:not([dir='rtl'])
  .rdp-button.rdp-day_range_end:not(.rdp-day_range_start):hover {
  color: white !important;
}
.customDatePicker .rdp-head_cell {
  color: #607D8B;
  font-size: 13px;
  font-weight: 600;
}
.customDatePicker .rdp-day {
  font-weight: 600;
  font-size: 14px;
  line-height: 17.76;
  color: #000000;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
}

.customDatePicker .rdp-button:focus {
  color: rgba(0, 0, 0, 0.87) !important;
}

.customDatePicker .rdp-day_today:not(.rdp-day_selected) {
  color: #6868fe !important
}

.customDatePicker .rdp-day_today:not(.rdp-day_selected):focus {
  color: #6868fe !important
}
