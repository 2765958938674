.ag-theme-material {
  --ag-header-height: 50px;
  --ag-data-color: rgba(0, 0, 0, 0.87);
  --ag-header-background-color: #ECEEF6;
  --ag-header-foreground-color: #ECEEF6;
  --ag-header-cell-hover-background-color: rgba(0, 0, 0, 0.04);
  --ag-header-cell-moving-background-color: rgba(0, 0, 0, 0.04);
  --ag-font-family: Manrope, Roboto, 'sans-serif';
  --ag-font-size: 15px;
  --ag-row-hover-color: rgba(156, 39, 176, 0.08);
  --ag-checkbox-unchecked-color: rgba(0, 0, 0, 0.3);
  --ag-checkbox-checked-color: rgba(104, 104, 254, 1);
  --ag-icon-size: 17px;
  --ag-cell-widget-spacing: 8px;
  /*--ag-header-cell-hover-background-color: 'transparent';*/
}

.ag-floating-top{
  width: calc(100% + 6px);
}
.ag-header {
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 3px 4px -4px rgb(0 0 0 / 50%);
}

.ag-ltr .ag-header-cell-resize {
  border-left: 1px solid #b4bbd7;
  height: 22px;
  top: auto;
  bottom: auto;
}

.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border: none;
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border: none;
}

.ag-pinned-right-header {
  border: none;
}

.ag-pinned-left-header {
  border: none;
}

.ag-theme-material .ag-header-cell {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.87);
}
.ag-theme-material .ag-cell {
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}
.ag-theme-material .ag-row {
  cursor: pointer;
  border: none;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.06);
}
.ag-row:hover span.boldTextOnHover {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  transition: ease all 0.2s;
}

.ag-row:hover span.templateAttached {
  color: #000;
  text-decoration: underline;
  font-weight: 600;
  transition: ease all 0.2s;
  cursor: pointer;
}

.ag-row:hover .actions {
  display: block !important;
}

.ag-cell:hover .editIcon {
  display: block !important;
}
.ag-cell .ag-icon-tree-closed,
.ag-icon ag-icon-tree-open {
  font-size: 22px;
  line-height: 22px;
  color: #9e9e9e;
}

.ag-cell-label-container {
  padding: 0;
}


.ag-ltr .ag-sort-indicator-icon {
  padding-left: 4px;
  color: rgba(0, 0, 0, 0.54);
}
.ag-cell-wrapper .ag-group-contracted, .ag-cell-wrapper .ag-group-expanded {
  display: none !important;
}
.ag-full-width-row .ag-cell-wrapper.ag-row-group{
  padding-left: 12px;
}
.ag-row-hover.ag-full-width-row.ag-row-group::before{
  background: transparent;
}
.ag-theme-material.ag-dnd-ghost{
  width: 450px;
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  box-shadow: inset rgba(0,0,0,0.1) 0px 1px 3px 0px, rgba(0,0,0,0.06) 0px 1px 2px 0px;
  font-size: 15px;
  font-weight: 600;
  color: rgba(0,0,0,0.87);
}
