.left-sidebar {
  width: 440px !important;
  box-shadow: 1px 0px 0px rgba(44, 36, 232, 0.11);
  height: calc(100vh - 72px);
  overflow-y: hidden;
  overflow-x: hidden;
  left: 0;
  top: 62px;

  &.hotel-manage-sidebar {
    background-color: $white;
    padding: 20px 15px;

    ul {
      li {
        display: flex;
        margin-bottom: 5px;

        a {
          padding: 10px 15px;
          color: $text-primary;
          font-weight: 500;
          font-size: $font-14;
          line-height: 24px;
          border-radius: 4px;
          width: 100%;
        }

        &.active,
        &:active,
        &:hover {
          a {
            background-color: $secondary;
            color: $white;
          }
        }
      }
    }
  }

  &.task-sidebar {
    background-color: $white;
    padding: 20px 15px;
  }

  &.checklist-sidebar {
    overflow: auto;

    form {
      .form-title {
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: $text-secondary-light;
        padding: 30px 25px 15px;
      }

      .btnbox {
        padding: 15px 25px 30px;
        border-bottom: 1px solid $border-light;
      }

      .add-info-box {
        padding: 15px 25px;
        border-bottom: 1px solid $border-light;

        .form-group {
          .form-control {
            background: transparent;
            border: none;
            padding: 0;
            height: auto;
          }

          span {
            display: block;
            font-weight: normal;
            font-size: $font-14;
            line-height: 18px;
            color: rgba(66, 63, 121, 0.7);
          }
        }
      }

      ul {
        &.folder-list {
          li {
            &.item {
              border-bottom: 1px solid $border-light;
              padding: 15px 25px;

              .avtar-wraper {
                position: relative;
                align-items: flex-start;

                .imgbox {
                  width: 35px;
                  height: 35px;
                  background-color: $avtar-bg;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &.name-img-box {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                  }

                  img {
                    width: 35px;
                    height: 35px;
                    border-radius: 50px;
                  }
                }

                h6 {
                  font-weight: 500;
                  font-size: $font-16;
                }
              }

              .action-btn-wrap {
                position: absolute;
                right: -15px;
                top: 0;
              }
            }
          }
        }
      }
    }
  }

  &.communication-sidebar {
    padding: 0;
    background: white;

    .communication-sidenav-form {
      .archive-Sidebar-title {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        height: 54px;
        line-height: 140%;
        color: #252350;
        display: flex;
        align-items: center;

        .icon-left-angle {
          padding-right: 34px !important;
          margin-left: 22px !important;
        }

        .icon-left-angle:before {
          color: #252350;
        }
      }
    }

    .archived-msg-link {
      font-weight: 500;
      font-size: $font-14;
      line-height: 24px;
      color: $text-light;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      position: relative;
      padding: 8px 25px;
      border-bottom: 1px solid $border-light;

      span {
        margin-right: 15px;
      }
    }
  }
}

.DayPicker {
  width: 100%;

  .DayPicker-Month {
    margin-top: 0;
  }

  .DayPicker-NavButton--prev {
    right: 20px !important;
    margin-right: 0;
    background-image: url('../../img/left-angle.svg');
  }

  .DayPicker-NavButton--next {
    background-image: url('../../img/right-angle.svg');
  }

  .DayPicker-NavButton {
    right: 0;
    top: 0;
    border: 0px solid $text-grey-light;
    width: 22px;
    height: 22px;
    background-color: $white;
    border-radius: 50px;
    background-size: 5px;
  }

  .DayPicker-Caption {
    text-align: center;

    & > div {
      text-align: left;
      font-weight: normal;
      font-size: $font-14;
      line-height: 24px;
    }
  }

  .DayPicker-Weekday {
    font-weight: 500;
    font-size: $font-12;
    line-height: 24px;
  }

  .DayPicker-Day--today {
    background-color: #eaecff;
    font-weight: 500;
  }

  .DayPicker-Day {
    font-weight: normal;
    font-size: $font-14;
    line-height: 24px;
    color: $table-head-text;
    border-radius: 6px;
    width: 36px;
    height: 42px;
    padding: 3px 13px;
  }
}

.DayPicker.SelectableDate {
  .DayPicker-Day {
    border-radius: 0px;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background-color: $secondary;
  }

  .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    filter: brightness(110%);
  }

  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}

.header-date-dd,
.header-date-dd.btn-primary,
.header-date-dd.btn-primary:hover,
.show > .header-date-dd.btn-primary,
.active > .header-date-dd.btn-primary {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  background: transparent;
  border: transparent;
}

.date-range-picker {
  display: flex;
  flex-direction: row;
  padding: 15px;

  .date-range-picker-ranges {
    min-width: 130px;

    .date-range-picker-range {
      height: 32px;
      padding: 5px 12px;
      font-weight: 400;
      font-size: 0.85em;
      border-radius: 6px;

      &:hover,
      &.date-range-picker-selected-range {
        background-color: #6868fe;
        color: white;
      }
    }
  }

  .date-range-picker-calendar {
  }
}
