.password-set-lauout {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  @include media(down, $breakpoint-sm) {
    align-items: flex-start;
  }
  .reset-form {
    margin: 60px 0;
  }
  .back-btn {
    position: absolute;
    top: 40px;
    left: 60px;
    box-shadow: none;
    @include media(down, $breakpoint-sm) {
      left: 15px;
    }
    span {
      &::before {
        color: $dark-black;
        font-size: 18px;
      }
    }
  }

  form {
    max-width: 360px;
    width: 100%;
    padding: 40px 0;
    margin: 0 auto;
    h2 {
      margin-bottom: $margin-bottom-14;
    }
    &.password-set-form {
      .imgbox {
        margin: 0 auto 60px;
        width: 32px;
        @include media(down, $breakpoint-sm) {
          margin: 0 auto 30px;
        }
      }
      .sub-text {
        margin-bottom: $margin-bottom-30;
        font-size: $font-16;
        @include media(down, $breakpoint-lg) {
          margin-bottom: $margin-bottom-20;
        }
      }
      .btn {
        margin: 60px 0 160px;
        @include media(down, $breakpoint-sm) {
          margin: 20px 0 50px;
        }
      }
    }
  }
}
.email-confirmation-layout {
  .email-confirmation-form {
    text-align: center;
    .iconbox {
      width: 110px;
      height: 110px;
      margin: 50px auto 75px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .resend-info {
      margin: 47px 0 90px;
    }
    .btn {
      &.btn-text {
        margin: 0 auto;
        letter-spacing: 0.01em;
        font-size: $font-16;
      }
    }
    .sub-text {
      .link {
        display: block;
        color: $text-primary;
        font-weight: 500;
      }
    }
  }
}
