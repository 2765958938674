.hotal-management-layout {
  display: flex;
  background-color: $hotel-admin-bg;
  .hotel-manage-content-wrap {
    table {
      &.servces-table {
        max-width: 570px;
        margin: 0 auto 30px;
      }
    }
  }
  .hotel-rooms-wrap {
    // padding: 0 15px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    .common-tab-wrap {
      .tab-content {
        .tab-pane {
          position: relative;
          .floor-slid-btn-wrap {
            position: absolute;
            right: -15px;
            top: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: start;
            margin-top: 40vh;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 61px;
              height: 61px;
              background: #ffffff;
              box-shadow: 0px 4px 14px rgba(37, 35, 80, 0.18);
              border: none;
              color: $text-grey-dark;
              border-radius: 100%;
              span {
                font-size: 18px;
                margin-right: 0;
                &::before {
                  color: $text-grey-dark;
                }
              }
            }
          }
          .floor-slid-btn-wrap-left {
            position: absolute;
            left: -12px;
            z-index: 999;
            top: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: start;
            margin-top: 40vh;
            justify-content: center;
            padding: 10px;
            .btn {
              width: 61px;
              height: 61px;
              background: #ffffff;
              box-shadow: 0px 4px 14px rgba(37, 35, 80, 0.18);
              border: none;
              color: $text-grey-dark;
              border-radius: 100%;
              span {
                font-size: 18px;
                margin-right: 0;
                &::before {
                  color: $text-grey-dark;
                }
              }
            }
          }
        }
      }
    }
    .floor-wrap-outer {
      display: flex;
      position: relative;
      overflow: auto;
      margin-left: 80px;
      margin-right: 80px;
      overflow: scroll;
      .floor-wrap {
        padding: 35px 25px;
        width: 250px;
        border-right: 1px solid $border-light;
        flex-shrink: 0;
        
        &.room-available {
          background-color: rgba(166, 166, 197, 0.3);
        }
        &.inactive {
          background-color: rgba(166, 166, 197, 0.3);
        }
        .floor-title-wrap {
          margin-bottom: $margin-bottom-20;
          h4 {
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            color: $primary;
          }
        }

        .roombox {
          background: $white;
          box-shadow: 1px 1px 3px $border-light;
          border-radius: 3px;
          margin-bottom: 15px;
          padding: 12px 15px;
          position: relative;
          button {
            display: none;
          }
          &:hover {
            .action-btn-wrap {
              display: block;
            }
            button {
              display: block;
            }
          }
          &.inactive {
            background-color: rgb(110 110 118 / 30%);
          }
          .action-btn-wrap {
            position: absolute;
            right: 3px;
            top: 10px;
            display: none;
            .common-dropdown {
              .dropdown-toggle {
                padding: 0 5px;
              }
            }
          }
          h5 {
            font-weight: normal;
            color: $primary;
          }
          span {
            font-weight: normal;
            font-size: $font-12;
            line-height: 16px;
            color: $text-secondary;
            margin-right: 10px;
          }
        }
        .btn-dashed-bordered {
          min-height: 70px;
        }
      }
    }
    .area-wrap {
      padding: 40px 25px;
    }
  }
}
.booking-item-group {
  align-items: center;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    .link {
      width: 43px;
      height: 43px;
      border-radius: 100%;
      background-color: $dark-grey-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $text-secondary;
    }
    &.active {
      .link {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
/* Area card */

.area-card {
  background: $white;
  box-shadow: 1px 1px 3px $border-light;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 12px 15px;
  position: relative;
  min-height: 76px;
  .action-btn-wrap {
    position: absolute;
    right: 3px;
    top: 10px;
    .common-dropdown {
      .dropdown-toggle {
        padding: 0 5px;
      }
    }
  }
  h5 {
    font-weight: normal;
    color: $primary;
  }
  &.inactive {
    background-color: rgba(166, 166, 197, 0.3);
  }
}
/* Input Number */
.rs-input-number {
  border: 1px solid $text-extra-light;
  border-radius: 4px;
  height: 40px !important;
  overflow: hidden;
  .rs-input {
    border: none;
    text-align: center;
  }
  .rs-input-number-btn-group-vertical {
    .rs-btn {
      border: none;
      position: relative;
      min-width: 28px;
      &.rs-input-number-touchspin-down {
        color: $btn-primary;
        &::before {
          content: '-';
        }
      }
      &.rs-input-number-touchspin-up {
        color: $btn-primary;
        &::before {
          content: '+';
        }
      }

      svg {
        display: none;
      }
    }
  }
}
