.task-comments {
  font-size: 14px;
  .str-chat__li {
    margin: 0px;
    padding: 15px;
    position: relative;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
      .str-chat__message-text-inner {
        background: rgba(0, 0, 0, 0);
      }
    }
  }

  .message-wrapper {
    display: flex;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  .str-chat__avatar--circle {
    flex-basis: 35px !important;
    line-height: 24px !important;
    img {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .message-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
      color: #252350;
    }

    &-timestamp {
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      color: #a09fbc;
      margin-left: 6px;
    }
  }

  .msg-timestamp {
    justify-content: flex-end;
  }

  .str-chat__message-text {
    margin-top: 7px;
  }

  .str-chat__message-simple__actions {
    position: absolute;
    top: -14px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    border-radius: 100px;
    background: white;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.07);
    z-index: 1;
    padding: 0 4px 0;

    &__action--reactions {
      display: flex;
    }

    &__action--options {
      display: flex;
    }

    &__action--thread {
      display: flex;
    }
  }

  .str-chat__message-simple__actions {
    margin-top: 0;
    display: none;
  }

  .str-chat__message-actions-box {
    top: initial;
    left: -100px;
    border-radius: 16px 16px 0 16px;
  }

  .str-chat__message-text {
    display: flex;
    width: 100%;
  }
  .str-chat__message-attachment {
    margin-top: 7px;
    &--img {
      height: 120px;
      max-width: 120px;
      display: flex !important;
      border-radius: 6px;
    }
  }

  .str-chat__message-text-inner {
    position: relative;
    flex: 1;
    display: block;
    min-height: 32px;
    font-size: 15px;
    color: black;
    border-radius: 0;
    border: 0px;
    margin-left: 0;

    p {
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 0;
    }
  }
  .str-chat__input-flat-wrapper {
    .str-chat__avatar {
      border: none !important;
    }
  }
  .str-chat__avatar-fallback {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
}

.task-comments,
#message-list {
  .custom-message.message-header-name {
    font-weight: 600;
    font-size: 14px;
    color: $text-light-primary;
    margin-right: 8px;
    &.sender {
      text-align: right;
    }
  }
  .sender {
    .message-header-name {
      margin-left: 8px;
      margin-right: 0px;
    }
  }

  .timebox {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .user-message,
  .message-text {
    .message-attachments {
      display: inline-block;
      width: 100%;

      .str-chat__message-attachment--file {
        width: 180px;
        background: #f6f5ff;
        border: 1px solid #dbe2f0;
        border-radius: 4px;
        margin: 5px;
        float: left;

        .str-chat__message-attachment-file--item {
          display: flex;
          flex-direction: row;
          padding: 8px;

          .rfu-file-icon--small {
            display: block;
            height: 46px;
            margin-right: 10px;
          }

          .str-chat__message-attachment-file--item-text {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;

            a,
            span {
              font-family: 'Manrope';
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            a {
              font-size: 14px;
              color: #151339;
            }

            span {
              font-size: 13px;
              color: #423f79;
              display: block !important;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .namebox.sender {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .namebox.receiver {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .str-chat__message-attachment--gallery {
    display: block;
    clear: both;
    padding: 5px;

    .str-chat__gallery {
      display: flex;
      flex-direction: row;
    }

    .str-chat__gallery-image,
    .str-chat__gallery-placeholder {
      height: 120px;
      width: 120px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(235, 232, 232);
      border-radius: 6px;
      border: 1px grey;

      img {
        border-radius: 0px;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .str-chat__gallery-placeholder P {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.35);
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

#message-list {
  .str-chat__li.str-chat__li--top {
  }

  .str-chat__li.str-chat__li--top,
  .str-chat__li.str-chat__li--middle {
    .timebox {
      // display: none;
    }
  }

  .str-chat__li.str-chat__li--middle,
  .str-chat__li.str-chat__li--bottom {
    .imgbox img,
    .namebox .message-header-name {
      // display: none;
    }
  }

  .str-chat__li.str-chat__li--middle {
  }

  .str-chat__li.str-chat__li--bottom {
  }
}

.user-typing-message {
  position: absolute;
  bottom: 0px;
  left: 120px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  img {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.str-chat__typing-indicator__avatars {
  display: none;
}
