.select-box__multi-value{
  border-radius: 16px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
  padding: 4px;
  display: flex;
  align-items: center;
}
.select-box__option--is-selected{
  background-color: #e3e3ff !important;
}

.select-box__placeholder{
  font-size: 13px
}
