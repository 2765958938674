.user-table .ag-header {
    border: none;
    border-radius: 6;
    margin-bottom: 0;
    box-shadow: none;
    padding: 2;
  }
  
  .user-table .ag-header-cell {
    font-weight: 600;
    font-size: 14px;
    line-height: 16.39px;
    color: #212121;
  }
  .user-table .ag-ltr .ag-header-cell-resize {
    height: 14px;
  }