.str-chat__date-separator {
  position: relative;
  .str-chat__date-separator-date {
    background-color: $white;
    position: absolute;
    left: 0;
    right: 0;
    width: 160px;
    text-align: center;
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: "#8a8d90";
  }
}
.rfu-dropzone__notifier {
  display: none;
}
.str-chat__input-flat {
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  flex-shrink: 0;
  background-color: $white;
}
.str-chat__input-flat-wrapper {
  position: relative;
  padding-inline: 24px;
  padding-bottom: 20px;

  .str-chat__input-flat--textarea-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .rta__textarea {
      padding: 9px 12px !important;
      min-height: 40px !important;
    }

    .str-chat__emojiselect-wrapper {
      .str-chat__tooltip {
        display: none;
      }
    }
    .str-chat__fileupload-wrapper {
      margin-left: 24px;

      .str-chat__tooltip {
        display: none;
      }
      label {
        margin-bottom: 0 !important;
      }
      svg {
        height: 24px;
        width: 24px;
        fill: $light-action-active;
      }
    }

    .emoji-mart,
    .emoji-mart * {
      box-sizing: border-box;
      line-height: 1.15;
    }
    .str-chat__input--emojipicker {
      position: absolute;
      bottom: 60px;
      left: 0;
      .emoji-mart {
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
          sans-serif;
        font-size: 16px;
        display: inline-block;
        color: #222427;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background: #fff;
        padding: 15px;
        .emoji-mart-bar {
          width: 100%;
          border: 0 solid #d9d9d9;
        }
        .emoji-mart-bar:first-child {
          border-bottom-width: 1px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .emoji-mart-anchors {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          padding: 0 6px;
          line-height: 0;
          .emoji-mart-anchor-selected {
            color: var(--primary-color) !important;
            .emoji-mart-anchor-bar {
              bottom: 0;
            }
          }
          .emoji-mart-anchor {
            position: relative;
            display: block;
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
            color: #858585;
            text-align: center;
            padding: 12px 4px;
            overflow: hidden;
            transition: color 0.1s ease-out;
            margin: 0;
            box-shadow: none;
            background: none;
            border: none;
            svg {
              fill: currentColor;
              height: 18px;
              width: 18px;
            }
          }
        }
        .emoji-mart-search {
          margin-top: 6px;
          padding: 0 6px;
          position: relative;
        }
        .emoji-mart-search {
          input {
            font-size: 16px;
            display: block;
            width: 100%;
            padding: 5px 25px 6px 10px;
            border-radius: 5px;
            border: 1px solid #d9d9d9;
            outline: 0;
            -webkit-appearance: none;
          }
          .emoji-mart-sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }
          .emoji-mart-search-icon {
            position: absolute;
            top: 7px;
            right: 11px;
            z-index: 2;
            padding: 2px 5px 1px;
            border: none;
            background: none;
          }
        }
        .emoji-mart-scroll {
          overflow-y: scroll;
          overflow-x: hidden;
          height: 270px;
          padding: 0 6px 6px;
          will-change: transform;
          .emoji-mart-category-label {
            z-index: 2;
            position: relative;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            span {
              display: block;
              width: 100%;
              font-weight: 500;
              padding: 5px 6px;
              background-color: #fff;
              background-color: hsla(0, 0%, 100%, 0.95);
            }
          }
          .emoji-mart-category-list {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              margin: 0;
              padding: 0;
              display: inline-block;
              .emoji-mart-emoji {
                padding: 6px;
                border: none;
                background-color: transparent;
                span {
                  z-index: 1;
                  position: relative;
                  text-align: center;
                  cursor: default;
                  font-size: 24px;
                  display: inline-block;
                  width: 24px;
                  height: 24px;
                  word-break: keep-all;
                }
              }
            }
          }
        }
      }
    }
    .str-chat__textarea {
      width: 100%;
      flex: unset;
      .rta__textarea {
        width: 100%;
        border-radius: 6px;
        padding: 15px 50px 15px 15px;
        height: auto !important;
        font-size: 14px;
        max-height: 120px;
        border-color: #dbe2f0;
        resize: none;
      }
      .rta__autocomplete {
        padding: 20px 30px;
        box-shadow: 0px -1px 5px rgb(0 0 0 / 10%);
        margin-bottom: 3px;
        border-radius: 3px;
        .rta__list {
          .rta__list-header {
            margin-bottom: 15px;
            font-weight: 600;
          }
          .rta__item {
            margin-bottom: 15px;
            .str-chat__user-item {
              display: flex;
              align-items: center;
              .str-chat__avatar {
                border-radius: 50px;
                width: 20px;
                height: 20px;
                overflow: hidden;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }
    .str-chat__fileupload-wrapper {
      label {
        cursor: pointer;
        .rfu-file-input {
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }
        .str-chat__input-flat-fileupload {
          // position: absolute;
          cursor: pointer;
          // right: 55px;
          // bottom:  35px;
          width: 20px;
          height: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-size: 44px 44px;
          fill: rgba(0, 0, 0, 0.6);
          font-family: 'icomoon' !important;
          &::before {
            content: '\e91f';
            width: 20px;
            height: 20px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $light-action-active;
          }
          svg {
            display: none;
          }
        }
      }
    }
  }
  .str-chat__send-button {
    position: absolute;
    display: flex !important;
    bottom: 32px;
    right: 32px;
    background-color: transparent;
    border: none;
    color: $secondary;
    padding: 0;
    font-family: 'icomoon' !important;
    &::before {
      content: '\e941';
      width: 22px;
      height: 22px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary;
    }
    svg {
      display: none;
    }
  }
  .rfu-image-previewer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 44px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 8px 0;
    flex: unset;
    .rfu-image-previewer__image {
      width: 60px;
      height: 70px;
      position: relative;
      margin-right: 8px;
      margin-bottom: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 6px;
      .rfu-thumbnail__wrapper {
        width: 60px !important;
        height: 70px !important;
        border-radius: 6px;
        overflow: hidden;
      }
      .rfu-thumbnail__image {
        width: inherit;
        height: inherit;
        -o-object-fit: cover;
        object-fit: cover;
      }
      .rfu-icon-button {
        padding: 7px;
        display: block;
        cursor: pointer;
        visibility: hidden;

        &:before {
          content: 'x';
          visibility: visible;
          border: 1px solid rgba(255, 255, 255, 0.85);
          width: 14px;
          display: flex;
          height: 14px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          padding-bottom: 1px;
          background: rgba(255, 255, 255, 0.85);
          font-weight: 700;
          font-size: 10px;
          color: #7571c5;
          margin-top: 4px;
        }
      }
      .rfu-icon-button svg {
        width: 19px;
        margin-top: -5px;
      }
    }
    .rfu-thumbnail__overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 5px;
    }
    .rfu-image-upload-button {
      label {
        cursor: pointer;
      }
      .rfu-image-input {
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .rfu-thumbnail-placeholder {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border: 1px dashed #bfbfbf;
        border-radius: 4px;
        cursor: pointer;
      }
      svg {
        fill: #a0b2b8;
      }
    }
    .rfu-image-upload-button {
      display: none;
    }
  }
  .rfu-image-previewer__image--loaded .rfu-thumbnail__overlay {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.4)),
      to(rgba(0, 0, 0, 0))
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-thumbnail__overlay,
  .rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-icon-button {
    padding: 0;
  }
  .rfu-file-previewer {
    border: none;
    display: flex;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    ol {
      display: flex;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0;

      li.rfu-file-previewer__file {
        padding: 0;
        height: 70px;
        width: 60px;
        display: flex;
        max-width: 60px;
        display: block;
        background: linear-gradient(0deg, #f0f4fc, #f0f4fc), #c4c4c4;
        border: 1px solid #dbe2f0;
        border-radius: 6px;
        margin-right: 8px;
        margin-bottom: 7px;

        .rfu-file-icon--small {
        }

        svg {
          display: block;
          margin: auto;
          margin-top: 17px;
          height: 27px;
        }

        a {
          display: block;
          margin: 5px 5px 0px;
          width: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          color: #252350;
        }

        span.rfu-file-previewer__close-button {
          margin-left: 40px;
          margin-top: -60px;
          display: block;
          cursor: pointer;
          visibility: hidden;

          &:before {
            content: 'x';
            visibility: visible;
            border: 1px solid rgba(255, 255, 255, 0.85);
            width: 14px;
            display: flex;
            height: 14px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            padding-bottom: 1px;
            background: rgba(255, 255, 255, 0.85);
            font-weight: 700;
            font-size: 10px;
            color: #7571c5;
          }
        }
      }
    }
  }
}

.str-chat__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 123px);
  .rta__textarea.str-chat__textarea__textarea {
    min-height: 40px !important;
    height: 40px !important;
  }
}

.chat-searchbox {
  .search-info-wrap {
    position: absolute;
    max-height: 260px;
    overflow: scroll;
    left: 0;
    top: 43px;
    background: $white;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 1;
    .result-count-text {
      font-size: 11px;
      line-height: 13px;
      color: $primary;
      opacity: 0.7;
      padding: 18px 20px 10px;
      display: block;
    }
    .searched-item {
      padding: 18px 20px 10px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      .info {
        width: calc(100% - 120px);
        padding-right: 15px;
        p {
          font-weight: 300;
          font-size: 14px;
          line-height: 140%;
          color: $text-secondary-dark;
          letter-spacing: 0.01em;
          strong {
            margin-right: 5px;
            font-weight: 500;
          }
        }
        .time {
          letter-spacing: 0.01em;
          font-size: 12px;
          line-height: 16px;
          color: $text-secondary-dark;
          opacity: 0.5;
        }
      }
      .avtar-wraper {
        flex-shrink: 0;
        .namebox {
          h6 {
            color: $primary;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.create-group-form {
  height: 100%;

  .str-chat {
    background-color: white !important;
  }

  .str-chat__gallery-image {
    pointer-events: none;
  }

  .form-head-section {
    margin-bottom: 20px;
    padding: 20px 20px 1px;

    box-shadow: 0px 1px 0px #dbe2f0;

    .form-group {
      margin-bottom: 10px;
    }
  }

  .candidate-group-members {
    display: inline-block;
    width: 100%;

    .candidate-group-members-to-label {
      float: left;
      padding: 6px;
    }

    .select-wrap .select-box__control {
      border-color: #fff !important;
    }

    .select-box__menu {
      z-index: 2;
    }
  }

  .create-group-naming {
    padding: 10px 40px;
    margin: 20px 30px;
    background: #f2f5f9;
    border-radius: 6px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    .row {
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-family: 'Manrope';
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;

      .group-name-input-div {
        width: calc(100% - 115px);
      }

      .btn.btn-primary {
        width: 90px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: #6868fe;
    }
  }

  .react-select-add-to-group-option {
    display: flex;

    label {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #252350;
    }

    .react-select-add-to-group-option-checkbox {
      width: 35px;
      padding: 3px;

      input[type='checkbox'] {
        width: 16px;
        height: 16px;

        &:checked::before {
          background: #6868fe;
        }
      }
    }

    .react-select-add-to-group-option-img {
      width: 35px;

      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
    }
  }

  .form-header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .str-chat__container {
      height: calc(100vh - 200px);
      justify-content: space-between;

      .messages-list {
        padding: 0px 5px 5px 10px;
        overflow: auto;
      }
    }
  }
}

.el-ellipses {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-title {
  font-weight: 700;
  font-size: 18px;
  color: $text-light-primary;
}

.send-btn {
  min-width: unset;
  width: 36px;
  height: 36px;
  margin-top: 80px;
}
