  .users-table .ag-header {
    border: none;
    border-radius: 8;
    margin-bottom: 0;
    box-shadow: none;
    background-color: #f5f5f5;
    margin-top: 2px;
  }
  
  .users-table .ag-header-cell {
    color: #212121;
    font-weight: 600;
    font-size: 13px;
    line-height: 17.76px;
  }
  .users-table .ag-ltr .ag-header-cell-resize {
    height: 14px;
  }
  