.taskListSummaryCnt .ag-header {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  box-shadow: none;
}
.taskListSummaryCnt .ag-ltr .ag-header-cell-resize {
  height: 14px;
}

.taskListSummaryCnt .ag-row:hover .template-name-cnt > .name {
  font-weight: 700;
  text-decoration: underline;
}
